import {
  Flex,
  Title,
  Button,
  Paper,
  Text,
  ActionIcon,
  Group,
  Tooltip,
} from "@mantine/core";
import { IconEdit, IconExclamationMark } from "@tabler/icons-react";
import { openEventDatesModal } from "./EventDatesModal";
import dayjs from "dayjs";
import { useGetEventDates } from "../../../api/event";

function EventDates({ event }) {
  const { data } = useGetEventDates(event?.id);

  return (
    <Flex direction="column">
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        mt="sm"
        mb="xs"
      >
        <Group align="center">
          <Title order={2}>Dates</Title>
          {data?.hasSessionsWithoutDates && (
            <Tooltip
              label="There are sessions with dates outside of the event dates. Please update sessions."
              multiline
              w={250}
            >
              <ActionIcon color="red" size="sm" radius="xl">
                <IconExclamationMark />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
        <Button
          size="sm"
          variant="light"
          color="blue"
          onClick={() => {
            openEventDatesModal(event);
          }}
          leftSection={<IconEdit />}
        >
          Edit dates
        </Button>
      </Flex>
      <Paper withBorder p="sm" shadow="xs" radius="md">
        <Flex justify="space-between" mb="sm">
          <Text fw="bold" size="lg">
            Start date
          </Text>
          <Text fw="bold" size="lg">
            End date
          </Text>
        </Flex>
        <Flex justify="space-between">
          {event?.dates?.length > 0 ? (
            event.dates.map((date) => {
              return (
                <Text key={date.id}>
                  {dayjs(date.date).format("DD MMM YYYY")}
                </Text>
              );
            })
          ) : (
            <Text c="red">No dates.</Text>
          )}
        </Flex>
      </Paper>
    </Flex>
  );
}

export default EventDates;
