import { Paper, Title, Text, Timeline, Flex } from "@mantine/core";
import { IconCheck, IconClock } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";

function SessionCards({ session }) {
  const [activeState, setActiveState] = useState();

  // Write function for determining the active state of the timeline undefined - the
  // session has not started yet, 0 - the session is active, 1 - the session has
  // ended
  const determineActive = useCallback(() => {
    const now = dayjs();
    const starting = dayjs(session.starting);
    const ending = dayjs(session.ending);

    if (now.isBefore(starting)) {
      return undefined;
    } else if (now.isAfter(starting) && now.isBefore(ending)) {
      return 0;
    } else if (now.isAfter(ending)) {
      return 1;
    }
  }, [session.starting, session.ending]);

  // Set the active state of the timeline every minute
  useEffect(() => {
    setActiveState(determineActive());

    const interval = setInterval(() => {
      setActiveState(determineActive());
    }, 60000);

    return () => clearInterval(interval);
  }, [determineActive]);

  return (
    <Paper shadow="xs" p="sm" withBorder key={session.id}>
      <Flex gap="lg">
        <Timeline active={activeState} bulletSize={24} py="xs">
          <Timeline.Item
            bullet={<IconClock size={18} />}
            title={dayjs(session.starting).format("HH:mm")}
          >
            <Text size="sm" c="dimmed">
              {dayjs(session?.ending).diff(dayjs(session?.starting), "m")}{" "}
              minutes
            </Text>
          </Timeline.Item>

          <Timeline.Item
            bullet={<IconCheck size={18} />}
            title={dayjs(session.ending).format("HH:mm")}
          ></Timeline.Item>
        </Timeline>

        <Flex direction="column" flex={1}>
          <Title order={3}>{session.title}</Title>
          <Text size="sm" c="dimmed" lineClamp={3}>
            {session.description}
          </Text>
          {session.speakers?.length > 0 && (
            <Text size="sm" fw="bold" mt="xs">
              Speakers:{" "}
              <Text span>
                {session.speakers
                  .map((speaker) => `${speaker.firstName} ${speaker.lastName}`)
                  .join(", ")}
              </Text>
            </Text>
          )}
        </Flex>
      </Flex>
    </Paper>
  );
}

export default SessionCards;
