import React from "react";
import { useGetPublicSessions } from "../api/session";
import { useLocation } from "react-router-dom";
import LoadingPage from "../components/common/LoadingPage";
import NotFound from "./NotFound";
import { Flex, Image, Title } from "@mantine/core";
import congrsLogo from "../assets/logo-02.svg";
import dayjs from "dayjs";
import SessionCards from "../components/Cards/SessionCards";

function PublicSessions() {
  // Get search params
  const location = useLocation();

  // Get eventId from search params
  const searchParams = new URLSearchParams(location.search);

  const params = {
    eventId: searchParams.get("eventId"),
    locationId: searchParams.get("locationId"),
    dateId: searchParams.get("dateId"),
  };

  const { data, isPending } = useGetPublicSessions(params);

  if (!params.eventId) {
    return <NotFound />;
  }

  if (isPending) {
    return <LoadingPage />;
  }

  const groupedSessions = data?.sessions.reduce((acc, session) => {
    const date = dayjs(session.starting).format("YYYY-MM-DD");

    if (!acc[date]) {
      acc[date] = {};
    }

    if (!acc[date][session.location.name]) {
      acc[date][session.location.name] = [];
    }

    acc[date][session.location.name].push(session);
    return acc;
  }, {});

  // Sort dates chronologically
  const sortedDates = Object.keys(groupedSessions || {}).sort((a, b) =>
    dayjs(a).diff(dayjs(b))
  );

  // Sort sessions within each location by starting time
  sortedDates.forEach((date) => {
    Object.keys(groupedSessions[date]).forEach((locationId) => {
      groupedSessions[date][locationId].sort((a, b) =>
        dayjs(a.starting).diff(dayjs(b.starting))
      );
    });
  });

  return (
    <Flex direction="column" px="md">
      <Flex direction="row" justify="space-between" align="center">
        <Image
          src={congrsLogo}
          alt="RoundCube logo"
          style={{ maxHeight: "150px" }}
        />
        {data?.event && (
          <Title style={{ textAlign: "right" }}>{data.event.name}</Title>
        )}
      </Flex>

      {sortedDates.map((date) => {
        return (
          <Flex direction="column" gap="sm" key={date} mb="lg">
            <Title order={2}>
              {dayjs(date).format("dddd - MMMM DD, YYYY")}
            </Title>
            {Object.keys(groupedSessions[date]).map((locationId) => {
              return (
                <Flex direction="column" gap="sm" key={locationId}>
                  <Title order={3}>{locationId}</Title>
                  {groupedSessions[date][locationId].map((session) => {
                    return <SessionCards session={session} />;
                  })}
                </Flex>
              );
            })}
          </Flex>
        );
      })}
    </Flex>
  );
}

export default PublicSessions;
