import { Container, Loader, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useContext, useState } from "react";

import { useGetWorkspaces } from "../api/workspace";
import WorkspacesCards from "../components/Workspaces/WorkspacesCards";
import WorkspacesTable from "../components/Workspaces/WorkspacesTable";
import { AuthContext } from "../services/context";
import { useSetActionButtons } from "../services/ActionButtonsContext";
import { IconPlus } from "@tabler/icons-react";

function Workspaces() {
  useSetActionButtons([
    {
      label: "Create",
      icon: <IconPlus />,
      navigate: "./workspaces/create",
    },
  ]);

  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);

  const [sort, setSort] = useState({
    columnAccessor: "name",
    direction: "asc",
  });

  const { data, isPending } = useGetWorkspaces({ page, sort });

  const isSysAdmin = user.role === "sysAdmin";
  const smallScreen = useMediaQuery("(max-width: 640px)");

  if (isPending) return <Loader />;

  if (!data || data?.data.length === 0) {
    return (
      <Container>
        <Title order={2}>No workspaces found.</Title>
        <Text c="red">Please contact support.</Text>
      </Container>
    );
  }

  return (
    <>
      {smallScreen ? (
        <WorkspacesCards
          data={data}
          isLoading={isPending}
          isSysAdmin={isSysAdmin}
        />
      ) : (
        <WorkspacesTable
          data={data}
          isLoading={isPending}
          isSysAdmin={isSysAdmin}
          page={page}
          setPage={setPage}
          sortStatus={sort}
          setSort={setSort}
        />
      )}
    </>
  );
}

export default Workspaces;
