import { useContext, useState } from "react";

import { DataTable } from "../DataTable";
import { AuthContext } from "../../services/context";
import { useGetPublicSessions } from "../../api/session";
import { getCurrentWorkspace } from "../../services/userConfig";
import { Flex, Radio, Group, Anchor, Text, Button } from "@mantine/core";
import { useGetEvent, useGetEventLocations } from "../../api/event";
import { Link } from "react-router-dom";
import { ButtonCopy } from "../Buttons/CopyLinkButton";
import { appendSearchParams } from "../../services/helper";
import { IconExternalLink } from "@tabler/icons-react";

function PublicSessionsTable() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const { data: locations } = useGetEventLocations(eventId);
  const { data: event } = useGetEvent(eventId);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const baseUrl = window.location.origin;
  const publicLink = appendSearchParams(`${baseUrl}/public-session`, {
    eventId,
    locationId: selectedLocation,
    dateId: selectedDate,
  });

  const { data, isPending } = useGetPublicSessions({
    eventId,
    locationId: selectedLocation,
    dateId: selectedDate,
  });

  if (event?.isPublic === false) {
    return (
      <Text>
        Event is not publicly available.{" "}
        <Anchor component={Link} to="/app/events/customize">
          Go to customization
        </Anchor>{" "}
        to make it public, or{" "}
        <Anchor component={Link} to="/app/sessions">
          go back to sessions
        </Anchor>
        .
      </Text>
    );
  }

  return (
    <Flex direction="column" gap="md">
      <Radio.Group
        name="location"
        label="Select session location"
        value={selectedLocation}
        onChange={(value) => {
          // If value of the radio button is null,
          // onChange reads it like an empty string
          // and sets it as a string in the state.

          // We need to convert it back to null
          if (value === "") {
            value = null;
          }

          setSelectedLocation(value);
        }}
      >
        <Group>
          <Radio value={null} label="All Locations" />
          {locations?.map(
            (location) =>
              location.sessionCount > 0 && (
                <Radio
                  key={location.id}
                  value={String(location.id)}
                  label={location.name}
                />
              )
          )}
        </Group>
      </Radio.Group>

      <Radio.Group
        name="date"
        label="Select session dates"
        value={selectedDate}
        onChange={(value) => {
          // If value of the radio button is null,
          // onChange reads it like an empty string
          // and sets it as a string in the state.

          // We need to convert it back to null
          if (value === "") {
            value = null;
          }

          setSelectedDate(value);
        }}
      >
        <Group>
          <Radio value={null} label="All dates" />
          {event?.dates?.map((date) => (
            <Radio key={date.id} value={String(date.id)} label={date.date} />
          ))}
        </Group>
      </Radio.Group>

      <Group>
        <ButtonCopy link={publicLink} />
        <Button
          component={Anchor}
          href={publicLink}
          target="_blank"
          rel="noopener noreferrer"
          rightSection={<IconExternalLink />}
          variant="outline"
          radius="xl"
          size="md"
        >
          Open link in new tab
        </Button>
      </Group>

      <DataTable
        records={data?.sessions}
        columns={[{ accessor: "title" }]}
        fetching={isPending}
      />
    </Flex>
  );
}

export default PublicSessionsTable;
