import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Group,
  ActionIcon,
  Avatar,
  TextInput,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconEdit,
  IconX,
  IconSearch,
  IconSend2,
  IconTrash,
} from "@tabler/icons-react";
import { InlineEditableTable } from "../InlineEditableTable";
import TicketSwitch from "./TicketSwitch";
import { CurrencyInput } from "./CurrencyInput";
import { useGetPeopleTagsBySubtype } from "../../api/tag";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import {
  useCreateTicket,
  useDeleteTicket,
  useGetTicketColumns,
  useUpdateTicket,
  useUpdateCustomFields,
} from "../../api/tickets";
import { useUpdateUser } from "../../api/user";
import deleteModal from "../deleteModal";

function TicketsTable({
  data,
  pagination,
  sorting,
  isPending,
  actions,
  selectedIds,
  onSelectedIdsChange,
  filter,
}) {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;
  const [customColumns, setCustomColumns] = useState([]);
  // Keep track of the row being edited to use as param when making mutations calls to update user, ticket, and custom fields
  const [editingRow, setEditingRow] = useState(null);

  const { mutate: updateUser } = useUpdateUser(
    {
      id: editingRow,
    },
    { skip: !editingRow }
  );
  const { mutate: updateTicket } = useUpdateTicket(
    {
      ticketId: editingRow,
    },
    { skip: !editingRow }
  );

  const { mutate: updateCustomFields } = useUpdateCustomFields(
    {
      eventId: currentEventId,
    },
    {
      skip: !editingRow,
    }
  );

  const { mutate: createTicket } = useCreateTicket();

  const { data: tagsData, isPending: tagsPending } = useGetPeopleTagsBySubtype({
    eventId: currentEventId,
    subtype: "categoryOfUser",
  });

  const getAttendeeTagId = (tagsData) => {
    if (tagsData) {
      return tagsData.find((tag) => tag.title.toLowerCase() === "attendee")?.id;
    }
  };

  const tags = tagsData?.map((tag) => ({
    value: String(tag.id),
    label: tag.title,
  }));
  const { mutate: deleteTicket, isError, isSuccess, error } = useDeleteTicket();

  const { data: columnsData } = useGetTicketColumns({
    eventId: currentEventId,
  });

  const [selectedRecords, setSelectedRecords] = useState([]);

  const onSelectedRecordsChange = (selectedRecords) => {
    setSelectedRecords(selectedRecords);
    onSelectedIdsChange(selectedRecords.map((record) => Number(record.id)));
  };

  useEffect(() => {
    if (!selectedIds?.length) {
      setSelectedRecords([]);
    } else {
      setSelectedRecords(
        data?.data.filter((record) =>
          selectedIds.map(Number).includes(Number(record.id))
        )
      );
    }
  }, [selectedIds, data]);

  const saveRowAdd = (row) => {
    const { id, firstName, lastName, email, category, ...rest } = row;
    const attendee = {
      eventId: currentEventId,
      userData: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        categoryOfUser: category,
        customFields: rest,
      },
    };

    createTicket(attendee);
  };

  const saveRowEdit = async (row) => {
    setEditingRow(row.id);
    const originalRow = data.data?.find((r) => r.id === row.id);

    // call mutation to update the ticket
    const changedData = Object.keys(row).reduce((acc, key) => {
      if (row[key] !== originalRow[key]) {
        acc[key] = row[key];
      }
      return acc;
    }, {});

    const userFields = [
      "firstName",
      "lastName",
      "email",
      "occupation",
      "biography",
    ];
    const updatePromises = [];

    // If changed data is for user data, user should be updated
    if (userFields.some((field) => changedData[field] !== undefined)) {
      const userData = userFields.reduce((acc, field) => {
        if (changedData[field]) {
          acc[field] = changedData[field];
          delete changedData[field];
        }
        return acc;
      }, {});

      if (Object.keys(userData).length > 0) {
        updatePromises.push(updateUser(userData));
      }
    }

    // If changed data is for ticket data, ticket should be updated
    if (changedData.category) {
      updatePromises.push(
        updateTicket({ categoryOfUser: changedData.category })
      );
      delete changedData.categoryOfUser;
    }

    // If changed data has remaining keys, it is data for custom fields, custom fields should be updated
    if (Object.keys(changedData).length > 0) {
      updatePromises.push(
        updateCustomFields({ data: changedData, ticketId: row.id })
      );
    }

    try {
      await Promise.all(updatePromises);
      setEditingRow({ rowId: null, columnClicked: null });
    } catch (err) {
      console.error("Error on inline save:", err);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex =
      /^(?!.*\.\.)[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (columnsData) {
      const customColumns = columnsData.filter(
        (column) =>
          column.isVisible === true &&
          ![
            "picture",
            "firstName",
            "lastName",
            "email",
            "status",
            "category",
            "paid",
            "amountPaid",
            "isApproved",
          ].includes(column.name)
      );
      setCustomColumns(customColumns);
    }
  }, [columnsData]);

  const visibleColumns = columnsData
    ?.filter((column) => column.isVisible === true)
    .map((column) => column.name);

  return (
    <>
      <InlineEditableTable
        data={data?.data}
        createEmptyRow={() => ({
          id: "temp",
          firstName: filter.data?.firstName || "",
          lastName: filter.data?.lastName || "",
          email: filter.data?.email || "",
          category: filter.data?.category || getAttendeeTagId(tagsData),
          // Add custom fields to the empty row
          ...customColumns?.reduce((acc, column) => {
            acc[column.name] = filter.data?.[column.name] || "";
            return acc;
          }, {}),
        })}
        validateRow={(row) => {
          const errors = {};

          if (!row.firstName) {
            errors.firstName = "First name is required";
          }

          if (!row.lastName) {
            errors.lastName = "Last name is required";
          }

          if (!row.email) {
            errors.email = "Email is required";
          }

          if (!isValidEmail(row.email)) {
            errors.email = "Invalid email format";
          }

          return errors;
        }}
        onRowCreate={(row) => saveRowAdd(row)}
        onRowChange={(row) => saveRowEdit(row)}
        columns={[
          {
            accessor: "picture",
            width: "0%",
            editable: false,
            hidden: !visibleColumns?.includes("picture"),
            render: (row) => (
              <Group justify="center">
                <Avatar
                  src={row?.picture}
                  size="md"
                  radius="sm"
                  alt="avatar"
                  color="initials"
                  name={`${row?.firstName} ${row?.lastName}`}
                />
              </Group>
            ),
            align: "center",
          },
          {
            accessor: "firstName",
            editable: true,
            sortable: true,
            hidden: !visibleColumns?.includes("firstName"),
            inputProps: {
              placeholder: "First name",
            },
            filter: (
              <FilterSearch
                query={filter.data}
                setQuery={filter.setFilter}
                columnName="firstName"
              />
            ),
            filtering: filter?.data?.firstName,
          },
          {
            accessor: "lastName",
            sortable: true,
            editable: true,
            hidden: !visibleColumns?.includes("lastName"),
            inputProps: {
              placeholder: "Last name",
            },
            filter: (
              <FilterSearch
                query={filter.data}
                setQuery={filter.setFilter}
                columnName="lastName"
              />
            ),
            filtering: filter?.data?.lastName,
          },
          {
            accessor: "email",
            sortable: true,
            editable: true,
            hidden: !visibleColumns?.includes("email"),
            inputProps: {
              placeholder: "Email",
            },
            filter: (
              <FilterSearch
                query={filter.data}
                setQuery={filter.setFilter}
                columnName="email"
              />
            ),
            filtering: filter?.data?.email,
          },
          {
            accessor: "status",
            editable: false,
            sortable: true,
            hidden: !visibleColumns?.includes("status"),
            render: (row) => {
              if (!row.status) {
                return (
                  <Badge color="blue" variant="light">
                    New
                  </Badge>
                );
              }
              return (
                <Badge
                  color={row.status !== "bounced" ? "blue" : "red"}
                  variant="light"
                >
                  {row?.status}
                </Badge>
              );
            },

            filter: (
              <StatusFilter query={filter.data} setQuery={filter.setFilter} />
            ),
            filtering: filter?.data?.status,
          },
          {
            accessor: "category",
            render: (row) => {
              const tags = row?.tags?.filter(
                (tag) => tag.subtype === "categoryOfUser"
              );

              if (tags?.length === 0 || !tags) {
                return (
                  <Text c="gray" size="sm">
                    No category
                  </Text>
                );
              }

              return tags?.map((tag) => (
                <Badge color={tag.color} variant="light" key={tag.id}>
                  {tag.title}
                </Badge>
              ));
            },
            editable: true,
            hidden: !visibleColumns?.includes("category"),
            inputProps: {
              placeholder: "Category",
            },
            type: "select",
            options: tags,
            filter: (
              <CategoryFilter
                query={filter.data}
                setQuery={filter.setFilter}
                tags={tags}
                isPending={tagsPending}
              />
            ),
            filtering: filter?.data?.category,
          },
          {
            accessor: "paid",
            width: "0%",
            editable: false,
            hidden: !visibleColumns?.includes("paid"),
            render: (row) =>
              row.id !== "temp" && (
                <Group justify="center">
                  <TicketSwitch
                    ticketId={row.id}
                    checked={row.paid}
                    columnName="paid"
                  />
                </Group>
              ),
            sortable: true,
          },
          {
            accessor: "amountPaid",
            width: 200,
            editable: false,
            hidden: !visibleColumns?.includes("amountPaid"),
            render: (row) =>
              row.id !== "temp" && (
                <CurrencyInput
                  amountValue={{
                    amountPaid: row.amountPaid || "",
                    currency: row.currency || "RSD",
                  }}
                  ticketId={row.id}
                />
              ),
          },
          {
            accessor: "isApproved",
            title: "Approve",
            width: "0%",
            sortable: true,
            editable: false,
            hidden: !visibleColumns?.includes("isApproved"),
            render: (row) =>
              row.id !== "temp" && (
                <Group justify="center">
                  <TicketSwitch
                    ticketId={row.id}
                    checked={row.isApproved}
                    columnName="isApproved"
                  />
                </Group>
              ),
          },
          ...(customColumns || []).map((column) => {
            return {
              accessor: column.name,
              editable: true,
              hidden: !column.isVisible,
              filter: (
                <FilterSearch
                  query={filter.data}
                  setQuery={filter.setFilter}
                  columnName={column.name}
                />
              ),
              filtering: filter?.data?.[column.name],
            };
          }),
          {
            accessor: "actions",
            textAlign: "right",
            width: "0%",
            editable: false,
            render: (row) => (
              <Group justify="right" wrap="nowrap">
                <Tooltip
                  label={`Send Email to ${row.firstName} ${row.lastName}`}
                >
                  <ActionIcon
                    size="md"
                    variant="light"
                    color="green"
                    onClick={() =>
                      actions.openSendEmailModal({ ticketIds: [row.id] })
                    }
                  >
                    <IconSend2 />
                  </ActionIcon>
                </Tooltip>
                <ActionIcon
                  size="md"
                  variant="light"
                  component={Link}
                  to={`${row.id}`}
                >
                  <IconEdit />
                </ActionIcon>
                <Tooltip label="Delete">
                  <ActionIcon
                    size="md"
                    variant="light"
                    color="red"
                    onClick={() =>
                      deleteModal(
                        row,
                        `delete ${row.firstName} ${row.lastName}`,
                        deleteTicket,
                        isError,
                        error,
                        isSuccess
                      )
                    }
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        resizable={true}
        tableProps={{
          pinLastColumn: true,
          page: pagination.data.page,
          onPageChange: (page) =>
            pagination.setPagination({ ...pagination.data, page }),
          sortStatus: sorting.data,
          onSortStatusChange: sorting.setSorting,
          fetching: isPending,
          recordsPerPage: data?.pagination.perPage,
          totalRecords: data?.pagination.total,
          selectedRecords: selectedRecords,
          onSelectedRecordsChange: onSelectedRecordsChange,
        }}
      />
    </>
  );
}

function FilterSearch({ query, setQuery, columnName }) {
  // Transform camel case to readable text
  const camelToReadableColumnName = columnName
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .toLowerCase();
  return (
    <TextInput
      label="Tickets"
      description={`Show tickets whose ${camelToReadableColumnName} includes the specified text`}
      placeholder="Search tickets..."
      leftSection={<IconSearch size={16} />}
      rightSection={
        <ActionIcon
          size="sm"
          variant="transparent"
          c="dimmed"
          onClick={() => setQuery(null)}
        >
          <IconX size={14} />
        </ActionIcon>
      }
      value={query?.[columnName] || ""}
      onChange={(e) => {
        if (e.currentTarget.value === "") {
          setQuery(null);
          return;
        }
        setQuery({ [columnName]: e.currentTarget.value });
      }}
    />
  );
}

function CategoryFilter({ query, setQuery, tags, isPending }) {
  if (isPending) {
    <Text>Loading tags...</Text>;
  }

  return (
    <Select
      label="Category"
      description="Filter tickets by category"
      placeholder="Filter by category"
      data={tags}
      value={query?.category}
      onChange={(value) => {
        setQuery({ category: value });
      }}
      onClear={() => setQuery(null)}
      clearable
    />
  );
}

function StatusFilter({ query, setQuery }) {
  return (
    <Select
      label="Status"
      description="Filter tickets by status"
      placeholder="Filter by status"
      data={[
        { value: "new", label: "New" },
        { value: "sent", label: "Sent" },
        { value: "bounced", label: "Bounced" },
        { value: "received", label: "Received" },
        { value: "opened", label: "Opened" },
      ]}
      value={query?.status}
      onChange={(value) => {
        setQuery({ status: value });
      }}
      onClear={() => setQuery(null)}
      clearable
    />
  );
}

export default TicketsTable;
