import { useState, useEffect } from "react";

export const useInlineTableEdit = (initialData = []) => {
  const [tableData, setTableData] = useState(initialData);

  const [isAddingRows, setIsAddingRows] = useState(false);
  const [editingRowId, setEditingRowId] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setTableData(initialData);
  }, [initialData]);

  // This will add new empty row to the table
  // when clicked on the add button
  const handleAddNewRow = (createEmptyRow) => {
    // Prevent adding multiple rows at once
    if (isAddingRows) return;

    setIsAddingRows(true);
    const newRow = createEmptyRow();
    setTableData([newRow, ...tableData]);
  };

  // This will update the cell value in the table
  const handleCellChange = (id, column, value) => {
    const updatedData = tableData.map((row) =>
      row.id === id ? { ...row, [column]: value } : row
    );
    setTableData(updatedData);
  };

  const startEditing = (id) => {
    setIsAddingRows(true);
    setEditingRowId(id);
    setErrors({});
  };

  const cancelEditing = () => {
    setIsAddingRows(false);
    setEditingRowId(null);
    setErrors({});

    setTableData(initialData);
  };

  // This will remove the row from the table
  // when clicked on the cancel button
  const cancelRowAdd = (id) => {
    setTableData((prev) => prev.filter((row) => row.id !== id));
    setIsAddingRows(false);
    setErrors({});
  };

  return {
    tableData,
    setTableData,
    isAddingRows,
    editingRowId,
    errors,
    setErrors,
    handleAddNewRow,
    handleCellChange,
    startEditing,
    cancelEditing,
    cancelRowAdd,
    onSave: () => {
      setIsAddingRows(false);
      setEditingRowId(null);
    },
  };
};
