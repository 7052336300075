import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetEvents = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["events", params] },
    fetchOptions: { url: "/events", method: "GET", params },
  });

export const useGetEvent = (id) =>
  useApiQuery({
    queryOptions: { queryKey: ["event", { id }], enabled: !!id },
    fetchOptions: { url: `/events/${id}`, method: "GET" },
  });

export const useCreateEvent = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useCreateEvent" },
    fetchOptions: { url: "/events", method: "POST" },
    invalidateQueryKey: "events",
    successMessage: { title: "Event has been created", color: "green" },
  });

export const usePatchEvent = (id) =>
  useApiMutation({
    mutationOptions: { mutationKey: "usePatchEvent", enabled: !!id },
    fetchOptions: { url: `/events/${id}`, method: "PATCH" },
    invalidateQueryKey: "events",
    successMessage: { title: "Event has been updated", color: "green" },
  });

export const useDeleteEvent = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeleteEvent" },
    fetchOptions: { url: "/events", method: "DELETE" },
    invalidateQueryKey: "events",
    successMessage: { title: "Event has been deleted", color: "green" },
  });

export const useGetEventLocations = (eventId) => {
  return useApiQuery({
    queryOptions: { queryKey: ["eventLocations", eventId] },
    fetchOptions: { url: `/events/${eventId}/locations`, method: "GET" },
  });
};

export const useCreateEventLocation = (eventId) => {
  return useApiMutation({
    mutationOptions: { mutationKey: "useCreateEventLocation" },
    fetchOptions: { url: `/events/${eventId}/locations`, method: "POST" },
    invalidateQueryKey: ["eventLocations", eventId],
    successMessage: { title: "Location has been created", color: "green" },
  });
};

export const usePatchEventLocation = (eventId, locationId) => {
  return useApiMutation({
    mutationOptions: { mutationKey: "usePatchEventLocation" },
    fetchOptions: {
      url: `/events/${eventId}/locations/${locationId}`,
      method: "PATCH",
    },
    invalidateQueryKey: ["eventLocations", eventId],
    successMessage: { title: "Location has been updated", color: "green" },
  });
};

export const useGetLocation = (eventId, locationId) => {
  return useApiQuery({
    queryOptions: { queryKey: ["location", { eventId, locationId }] },
    fetchOptions: {
      url: `/events/${eventId}/locations/${locationId}`,
      method: "GET",
    },
  });
};

export const useDeleteEventLocation = (eventId, locationId) => {
  return useApiMutation({
    mutationOptions: { mutationKey: "useDeleteEventLocation" },
    fetchOptions: {
      url: `/events/${eventId}/locations/${locationId}`,
      method: "DELETE",
    },
    invalidateQueryKey: ["eventLocations", eventId],
    successMessage: { title: "Location has been deleted", color: "green" },
  });
};

export const useGetEventDates = (eventId) => {
  return useApiQuery({
    queryOptions: { queryKey: ["eventDates", eventId] },
    fetchOptions: { url: `/events/${eventId}/dates`, method: "GET" },
  });
};
