import { Button, Container, Paper, Title } from "@mantine/core";
import { useForm } from "react-hook-form";
import { Select, TextInput } from "react-hook-form-mantine";
import { extractDirtyData } from "../../utils/dirtyData";
import { useUpdateSpecialInvitation } from "../../api/announcements";
import ImageUpload from "../ImageUpload";

function EditSpecialInvite({ data, tags }) {
  const tagsOptions = [
    { value: "", label: "All ticket holders" },
    ...(tags ?? []).map((tag) => ({
      value: String(tag.id),
      label: `${tag.title} - (${tag.usageCount} users)`,
    })),
  ];

  const { mutate, isPending } = useUpdateSpecialInvitation(data.id);

  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm({
    defaultValues: {
      title: data.title,
      isVisible: String(data.isVisible),
      eventId: data?.eventId,
      targetedTagId: data?.targetedTagId ? String(data.targetedTagId) : "",
      image: data?.image,
    },
  });

  const onSubmit = (data) => {
    const dirtyData = extractDirtyData(data, dirtyFields);

    if (dirtyData.isVisible) {
      dirtyData.isVisible = dirtyData.isVisible === "true";
    }

    if (dirtyData.targetedTagId === "") {
      dirtyData.targetedTagId = null;
    }

    mutate(dirtyData, {
      onSuccess: () => {
        reset({ ...data, ...dirtyData });
      },
    });
  };

  return (
    <Container size={500}>
      <Title order={1}>Edit Special Invite</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Title"
            placeholder="Special Invite Title"
            control={control}
            name="title"
            required
          />

          <ImageUpload
            control={control}
            name="image"
            label="Image"
            defaultImage={data.image}
            mt="sm"
            resize
            aspectRatio={9 / 16}
          />

          <Select
            label="Targeted announcement"
            description="Select tag to target users"
            placeholder="Select targeted tag"
            mt="sm"
            name="targetedTagId"
            control={control}
            data={tagsOptions}
            allowDeselect={false}
          />

          <Select
            label="Visibility"
            placeholder="Select visibility"
            control={control}
            name="isVisible"
            required
            data={[
              { value: "true", label: "Visible" },
              { value: "false", label: "Not Visible" },
            ]}
            mt="sm"
          />
          <Button type="submit" mt={10} disabled={!isDirty} loading={isPending}>
            Edit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default EditSpecialInvite;
