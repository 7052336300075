import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  Fieldset,
  Paper,
  Text,
  TextInput as TextInputMantine,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { TextInput, Select, MultiSelect } from "react-hook-form-mantine";
import { Link } from "react-router-dom";

import { IconPlus, IconX } from "@tabler/icons-react";
import { useCreateSponsor } from "../../api/sponsors";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useGetSponsorLevels } from "../../api/sponsors";
import { useGetAllTickets } from "../../api/tickets";
import ImageUpload from "../ImageUpload";

function CreateSponsor() {
  const { user } = useContext(AuthContext);

  const [sponsorLevelOptions, setSponsorLevelOptions] = useState([]);
  const [sponsorRepOptions, setSponsorRepOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const currentWorkspace = getCurrentWorkspace(user);

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      levelId: "",
      description: "",
      links: [],
      logo: "",
      workspaceId: currentWorkspace?.id,
      eventId: currentWorkspace?.eventId,
      repIds: [],
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 3 },
        description: { type: "string" },
      },
    }),
  });

  // Calling this custom hook in order to create dynamic form for adding links and labels
  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const { mutate, isPending, isSuccess } = useCreateSponsor();

  const { data: sponsorLevels } = useGetSponsorLevels({
    eventId: currentWorkspace?.eventId,
  });

  const { data: tickets } = useGetAllTickets({
    eventId: currentWorkspace?.eventId,
  });

  useEffect(() => {
    if (sponsorLevels) {
      const opts = sponsorLevels
        .sort((a, b) => a.order - b.order)
        .map((level) => ({
          value: level.id.toString(),
          label: level.name,
        }));

      setSponsorLevelOptions(opts);
    }
  }, [sponsorLevels]);

  useEffect(() => {
    if (tickets) {
      const opts = tickets.data.map((ticket) => {
        const fullName = `${ticket.firstName} ${ticket.lastName}`;
        const occupation = ticket.occupation ? `, ${ticket.occupation}` : "";

        return {
          value: ticket.id.toString(),
          label: `${fullName}${occupation}`,
        };
      });

      setSponsorRepOptions(opts);
    }
  }, [tickets]);

  const onSubmit = (data) => {
    if (!data.levelId) {
      setErrorMessage("Sponsor level is required!");
      return;
    }

    if (data.repIds.length) {
      data.repIds = data.repIds.map((rep) => parseInt(rep));
    }
    mutate(data);
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            name="name"
            control={control}
            withAsterisk
            mt="md"
          />
          {sponsorLevelOptions.length === 0 ? (
            <>
              <Text size="sm" c="dimmed" mt="sm">
                No sponsor levels available. Please create a sponsor level{" "}
                <Text component={Link} to="/app/sponsors/levels" c="blue">
                  {" "}
                  here
                </Text>
                .
              </Text>
            </>
          ) : (
            <Select
              label="Sponsor level"
              placeholder="Select sponsor level"
              name="levelId"
              data={sponsorLevelOptions}
              control={control}
              mt="md"
              withAsterisk
            />
          )}

          <TextInput
            label="Description"
            placeholder="Description"
            type="text"
            name="description"
            control={control}
            mt="md"
          />

          <ImageUpload
            control={control}
            name="logo"
            label="Logo"
            description="Upload a logo for the sponsor"
            mt="md"
            resize
          />

          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link label"
                  {...register(`links.${index}.label`)}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`)}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}
            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          <MultiSelect
            label="Representative"
            placeholder="Select sponsor representative"
            name="repIds"
            data={sponsorRepOptions}
            control={control}
            mt="md"
            disabled={sponsorRepOptions.length === 0}
            description={
              sponsorRepOptions.length === 0
                ? "No representatives available. Please create tickets for this event."
                : ""
            }
            searchable
          />

          <Text c="red" mt="md">
            {errorMessage}
          </Text>

          <Button
            type="submit"
            mt="md"
            loading={isPending}
            disabled={isSuccess}
          >
            Create sponsor
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateSponsor;
