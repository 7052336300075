import { IconPlus } from "@tabler/icons-react";
import UsersTable from "../components/Users/UsersTable";
import { useSetActionButtons } from "../services/ActionButtonsContext";

function Users() {
  useSetActionButtons([
    {
      label: "Create",
      icon: <IconPlus />,
      navigate: "./users/create",
    },
  ]);
  return <UsersTable />;
}

export default Users;
