import { useMediaQuery } from "@mantine/hooks";
import { useContext, useState } from "react";
import SponsorsCards from "../components/Sponsors/SponsorsCard";
import SponsorsTable from "../components/Sponsors/SponsorsTable";

import { useGetSponsors, useGetSponsorReps } from "../api/sponsors";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useSetActionButtons } from "../services/ActionButtonsContext";
import { IconListNumbers, IconPlus } from "@tabler/icons-react";

function Sponsors() {
  useSetActionButtons([
    {
      label: "Levels",
      icon: <IconListNumbers />,
      navigate: "./sponsors/levels",
    },
    {
      label: "Create",
      icon: <IconPlus />,
      navigate: "./sponsors/create",
    },
  ]);

  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const smallScreen = useMediaQuery("(max-width: 640px)");
  const currentWorkspace = getCurrentWorkspace(user);

  const [sort, setSort] = useState([
    {
      columnAccessor: "sponsorLevels.order",
      direction: "asc",
    },
    {
      columnAccessor: "name",
      direction: "asc",
    },
  ]);
  const [filter, setFilter] = useState(null);

  const { data, isPending: isLoading } = useGetSponsors({
    page,
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
    sort,
    filter,
  });

  const { data: sponsorReps, isPending: isLoadingReps } = useGetSponsorReps({
    eventId: currentWorkspace.eventId,
  });

  return (
    <>
      {smallScreen ? (
        <SponsorsCards data={data} isLoading={isLoading} />
      ) : (
        <SponsorsTable
          data={data}
          isLoading={isLoading || isLoadingReps}
          page={page}
          setPage={setPage}
          sortStatus={sort}
          setSort={setSort}
          sponsorReps={sponsorReps}
          filter={{ levelFilter: filter, setFilter }}
        />
      )}
    </>
  );
}

export default Sponsors;
