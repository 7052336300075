import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Title, Paper, Button } from "@mantine/core";
import { TextInput, Select } from "react-hook-form-mantine";

import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useGetTags } from "../../api/tag";
import { useCreateSpecialInvitation } from "../../api/announcements";
import ImageUpload from "../ImageUpload";

function CreateSpecialInvite() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      title: "",
      isVisible: "false",
      eventId,
      targetedTagId: "",
      image: "",
    },
  });

  const { data: tags } = useGetTags({
    eventId,
    type: "people",
  });

  const tagsOptions = [
    { value: "", label: "All ticket holders" },
    ...(tags ?? []).map((tag) => ({
      value: String(tag.id),
      label: `${tag.title} - (${tag.usageCount} users)`,
    })),
  ];

  const { mutate, isSuccess, isPending } = useCreateSpecialInvitation();

  const onSubmit = (data) => {
    if (data.isVisible) {
      data.isVisible = data.isVisible === "true";
    }

    // Remove targetedTagId if it is an empty string
    // This is done because the API will not accept an empty string
    // it will translate to 0 and that is not what we want
    // I've tried to use null but it didn't work
    // And refactoring the API repo to accept null as value
    // could break other things, so this is a temporary solution
    if (data.targetedTagId === "") {
      delete data.targetedTagId;
    }

    mutate(data);
  };

  // Set eventId value to currentEventId If user changes current event, we need
  // to update eventId value in the form.
  useEffect(() => {
    setValue("eventId", eventId);
  }, [eventId, setValue]);

  // If announcement is created successfully, reset form
  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <Container size={500}>
      <Title order={1}>Create Special Invitation</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <TextInput
            label="Title"
            mt="sm"
            name="title"
            control={control}
            placeholder="Your title"
          />

          <ImageUpload
            control={control}
            name="image"
            label="Image"
            description="Upload an image for the special announcement"
            mt="sm"
            resize
            aspectRatio={9 / 16}
          />

          <Select
            label="Targeted announcement"
            description="Select tag to target users"
            placeholder="Select targeted tag"
            mt="sm"
            name="targetedTagId"
            control={control}
            data={tagsOptions}
            allowDeselect={false}
          />
          <Select
            label="Visibility"
            placeholder="Select visibility"
            mt="sm"
            name="isVisible"
            control={control}
            data={[
              { value: "true", label: "Visible" },
              { value: "false", label: "Hidden" },
            ]}
          />
          <Button type="submit" mt="sm" loading={isPending}>
            Create invitation
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateSpecialInvite;
