import { useState } from "react";
import { Button, Flex } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

import { useInlineTableEdit } from "../../utils/useInlineTableEdit";
import { EditableCell } from "./EditableCell";
import { EditActions } from "./EditActions";
import { DataTable } from "../DataTable";

/**
 * A component that renders an inline editable table with support for adding, editing, and validating rows.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array} props.data - The initial table data array
 * @param {Array} props.columns - Array of column configurations for the table
 * @param {Function} props.createEmptyRow - Function that returns an empty row object for new entries
 * @param {Function} props.onSave - Callback function when a row is saved
 * @param {Function} props.validateRow - Function to validate row data before saving
 * @param {Function} [props.renderHeader] - Optional custom header render function that receives (addNewRow: Function, isAddingRows: boolean) => JSX.Element
 * @param {Object} [props.tableProps={}] - Additional props to pass to the DataTable component
 *
 * @returns {JSX.Element} Rendered table component with inline editing capabilities
 *
 * @example
 * <InlineEditableTable
 *   data={tableData}
 *   columns={columns}
 *   createEmptyRow={() => ({ id: uuid(), name: '', age: '' })}
 *   onSave={(row) => handleSave(row)}
 *   validateRow={(row) => validateRowData(row)}
 *   renderHeader={(addNewRow, isAddingRows) => (
 *     <CustomHeader onAdd={addNewRow} disabled={isAddingRows} />
 *   )}
 * />
 */
export const InlineEditableTable = ({
  data,
  columns,
  createEmptyRow,
  validateRow,
  renderHeader,
  onRowCreate,
  onRowChange,
  tableProps = {},
  resizable = false,
}) => {
  const isFromExistingData = (id) => {
    return data?.some((row) => row.id === id);
  };

  const [columnClicked, setColumnClicked] = useState("");

  const {
    tableData,
    isAddingRows,
    editingRowId,
    errors,
    setErrors,
    handleAddNewRow,
    handleCellChange,
    startEditing,
    cancelEditing,
    cancelRowAdd,
    onSave: onSaveCallback,
  } = useInlineTableEdit(data);

  const addNewRow = () => handleAddNewRow(createEmptyRow);

  const handleSave = async (row) => {
    const validationErrors = validateRow(row);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // If the row is new, call the onRowCreate callback
    // to save the row to the server, otherwise call onRowChange
    if (!isFromExistingData(row.id)) {
      await onRowCreate(row);
    } else {
      await onRowChange(row);
    }
    onSaveCallback();
  };

  const enhanceColumns = (columns) => {
    const actionColumnIndex = columns.findIndex(
      (col) => col.accessor === "actions"
    );
    const baseColumns = columns.map((column) => ({
      ...column,
      render: (row) => {
        if (column.accessor === "actions") return undefined; // Skip actions, handle separately

        const isEditing = editingRowId === row.id;
        const isNew = !isFromExistingData(row.id);

        // If the column is non-editable, render null (empty content) when double-clicked
        if (!column.editable && editingRowId === row.id) {
          return null;
        }

        if (column.editable && (isEditing || isNew)) {
          return (
            <EditableCell
              isExisting={false}
              render={column.render ? () => column.render(row) : null}
              value={row[column.accessor]}
              onChange={(value) => {
                // clear the specific field error on change
                setErrors((prevErrors) => {
                  const { [column.accessor]: currentFieldError, ...rest } =
                    prevErrors;
                  return rest;
                });

                handleCellChange(row.id, column.accessor, value);
              }}
              error={errors[column.accessor]}
              inputProps={column.inputProps}
              onSave={() => handleSave(row)}
              onCancel={() => (isNew ? cancelRowAdd(row.id) : cancelEditing())}
              type={column?.type}
              options={column?.options}
              autoFocus={columnClicked === column.accessor}
            />
          );
        }
        return column.render ? column.render(row) : row[column.accessor];
      },
    }));

    const actionsColumn = {
      accessor: "actions",
      width: "0%",
      render: (row) => {
        const originalActions =
          actionColumnIndex !== -1
            ? columns[actionColumnIndex].render?.(row)
            : null;

        if (editingRowId === row.id) {
          return (
            <EditActions
              onSave={() => handleSave(row)}
              onCancel={cancelEditing}
            />
          );
        }

        if (!isFromExistingData(row.id)) {
          return (
            <EditActions
              onSave={() => handleSave(row)}
              onCancel={() => cancelRowAdd(row.id)}
            />
          );
        }

        return originalActions;
      },
    };

    return [
      ...baseColumns.filter((col) => col.accessor !== "actions"),
      actionsColumn,
    ];
  };

  const handleCellDoubleClick = ({ rowId, columnClicked }) => {
    startEditing(rowId);
    setColumnClicked(columnClicked);
  };

  return (
    <>
      {!renderHeader ? (
        <Flex align="center" justify="end" my="sm">
          <Button
            onClick={addNewRow}
            disabled={isAddingRows}
            leftSection={<IconPlus />}
          >
            Create
          </Button>
        </Flex>
      ) : (
        renderHeader(addNewRow, isAddingRows)
      )}

      <DataTable
        records={tableData}
        columns={enhanceColumns(columns)}
        resizable={resizable}
        onCellDoubleClick={(e) =>
          handleCellDoubleClick({
            rowId: e.record.id,
            columnClicked: e.column.accessor,
          })
        }
        {...tableProps}
      />
    </>
  );
};
