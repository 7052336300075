import { useParams } from "react-router-dom";
import { useState } from "react";
import { useGetTicket, useUpdateTicket } from "../../api/tickets";
import { useGetUser } from "../../api/user";
import { EditUser } from "../Users/EditUser";
import { Button, Container, Fieldset } from "@mantine/core";
import { useGetPeopleTagsBySubtype } from "../../api/tag";
import { useForm } from "react-hook-form";
import { MultiSelect, TextInput } from "react-hook-form-mantine";
import { capitalize } from "../../utils/textUtils";
import {
  useGetCustomFields,
  useUpdateCustomFields,
  useGetTicketColumns,
} from "../../api/tickets";
import { extractDirtyData } from "../../utils/dirtyData";

function Edit() {
  const { ticketId } = useParams();

  const { data: ticket } = useGetTicket({ ticketId });

  const { data: user } = useGetUser(ticket?.userId);

  return (
    <>
      {user && <EditUser user={user} />}
      {ticket && (
        <>
          <EditTicketTag ticket={ticket} />
          <EditTicketCustomFields ticket={ticket} />
        </>
      )}
    </>
  );
}

function EditTicketTag({ ticket }) {
  const { data } = useGetPeopleTagsBySubtype({
    eventId: ticket.eventId,
    subtype: "categoryOfUser",
  });

  const findUserTicketCategories = ticket.tags.filter(
    (tag) => tag.subtype === "categoryOfUser"
  );

  const { mutate, isPending } = useUpdateTicket({ ticketId: ticket.id });

  const defaultValues = {
    categoryOfUser: findUserTicketCategories?.map((tag) => String(tag.id)),
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues,
  });

  const handleTagUpdate = (data) => {
    mutate(
      {
        categoryOfUser: data.categoryOfUser,
      },
      {
        onSuccess: () => {
          reset({ categoryOfUser: data.categoryOfUser });
        },
        onError: () => {
          // Handle error
          reset({ ...defaultValues });
        },
      }
    );
  };

  return (
    <Container my="md">
      <form onSubmit={handleSubmit((data) => handleTagUpdate(data))}>
        <Fieldset legend="Ticket category">
          <MultiSelect
            label="Category"
            placeholder="Select category"
            data={data?.map((tag) => ({
              value: String(tag.id),
              label: capitalize(tag.title),
            }))}
            control={control}
            name="categoryOfUser"
          />
        </Fieldset>
        <Button
          type="submit"
          variant="light"
          color="blue"
          mt="md"
          disabled={!isDirty}
          loading={isPending}
        >
          Change category
        </Button>
      </form>
    </Container>
  );
}

const EditTicketCustomFields = ({ ticket }) => {
  const [errors, setErrors] = useState({});
  const { data: customFieldsData } = useGetCustomFields({
    eventId: ticket.eventId,
    ticketId: ticket.id,
  });

  const { data: columns } = useGetTicketColumns({
    eventId: ticket.eventId,
  });

  const customColumns = columns
    ? columns?.filter(
        (col) =>
          col.isVisible &&
          ![
            "picture",
            "firstName",
            "lastName",
            "email",
            "status",
            "category",
            "paid",
            "amountPaid",
            "isApproved",
          ].includes(col.name)
      )
    : [];

  const { mutate, isPending } = useUpdateCustomFields({
    eventId: ticket.eventId,
    ticketId: ticket.id,
  });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      customFields: customFieldsData?.reduce((acc, field) => {
        acc[field.name] = field.value;
        return acc;
      }, {}),
    },
  });

  const handleCustomFieldsUpdate = (data) => {
    const dirtyData = extractDirtyData(data, dirtyFields);

    mutate(
      {
        data: dirtyData,
        ticketId: ticket.id,
        eventId: ticket.eventId,
      },
      {
        onSuccess: () => {
          reset(data);
        },
      }
    );

    setErrors({});
  };

  return (
    <Container my="md">
      <form onSubmit={handleSubmit((data) => handleCustomFieldsUpdate(data))}>
        <Fieldset legend="Custom fields" name={"customFields"}>
          {customColumns?.map((field) => {
            // Find the default value for the custom field
            const customField = customFieldsData?.find(
              (cf) => cf.name === field.name && cf.ticketId === ticket.id
            );

            return (
              <TextInput
                key={field.name}
                label={field.name}
                control={control}
                name={field.name}
                defaultValue={customField?.value || ""}
                error={errors[field.name]}
                onChange={(e) => {
                  // Reset the error message when the user starts typing
                  setErrors((prev) => {
                    const { [field.name]: thisFieldError, ...rest } = prev;
                    return rest;
                  });
                  // If user types in only space, show an error message
                  if (
                    e.target.value.trim().length === 0 &&
                    e.target.value.length > 0
                  ) {
                    setErrors((prev) => ({
                      ...prev,
                      [field.name]: "Field value cannot contain only spaces",
                    }));
                  }
                }}
              />
            );
          })}
        </Fieldset>

        <Button
          type="submit"
          variant="light"
          color="blue"
          mt="md"
          disabled={!isDirty || Object.keys(errors).length > 0}
          loading={isPending}
        >
          Update custom fields
        </Button>
      </form>
    </Container>
  );
};

export default Edit;
