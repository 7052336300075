import { useContext, useState } from "react";

import { useGetPolls } from "../api/polls";
import PollsTable from "../components/Polls/PollsTable";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useParams } from "react-router-dom";
import { useSetActionButtons } from "../services/ActionButtonsContext";
import { IconPlus } from "@tabler/icons-react";

function Polls() {
  useSetActionButtons([
    {
      label: "Create",
      icon: <IconPlus />,
      navigate: "./polls/create",
    },
  ]);

  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const currentWorkspace = getCurrentWorkspace(user);
  const { sessionId } = useParams();

  const { data, isPending: isLoading } = useGetPolls({
    page,
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
    sessionId,
  });

  return (
    <PollsTable
      data={data}
      isLoading={isLoading}
      role={user.role}
      page={page}
      setPage={setPage}
    />
  );
}

export default Polls;
