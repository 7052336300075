import { Container, Paper, Title, Button, Text } from "@mantine/core";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Select, TextInput } from "react-hook-form-mantine";

import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useCreateTicket } from "../../api/tickets";
import { useGetPeopleTagsBySubtype } from "../../api/tag";
import { capitalize } from "../../utils/textUtils";
import { Link } from "react-router-dom";
import { ajvResolver } from "@hookform/resolvers/ajv";
import { fullFormats } from "ajv-formats/dist/formats";

function CreateTicket() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const { data, isSuccess } = useGetPeopleTagsBySubtype({
    eventId: currentEventId,
    subtype: "categoryOfUser",
  });

  const categoryOfUserOptions = data?.map((tag) => ({
    value: String(tag.id),
    label: capitalize(tag.title),
  }));

  return (
    <Container size={500}>
      <Title>Create Ticket</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        {isSuccess && (
          <Form currentEventId={currentEventId} tags={categoryOfUserOptions} />
        )}
      </Paper>
    </Container>
  );
}

const Form = ({ currentEventId, tags }) => {
  const { mutate, isPending, isSuccess } = useCreateTicket();

  const schema = {
    type: "object",
    properties: {
      firstName: { type: "string", minLength: 1 },
      lastName: { type: "string", minLength: 1 },
      email: { type: "string", minLength: 1, format: "email" },
      categoryOfUser: { type: "number" },
    },
    required: ["firstName", "lastName", "email", "categoryOfUser"],
  };

  const attendeeTag = tags.find(
    (tag) => tag.label.toLowerCase() === "attendee"
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      categoryOfUser: attendeeTag?.value,
    },
    resolver: ajvResolver(schema, { formats: fullFormats, coerceTypes: true }),
  });

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  const createTicket = (data) => {
    const ticket = {
      eventId: currentEventId,
      userData: { ...data },
    };

    mutate(ticket);
  };

  return (
    <form onSubmit={handleSubmit((data) => createTicket(data))}>
      <TextInput
        label="First Name"
        placeholder="First Name"
        type="text"
        name="firstName"
        control={control}
        withAsterisk
        mt="md"
      />
      <TextInput
        label="Last Name"
        placeholder="Last Name"
        type="text"
        name="lastName"
        control={control}
        withAsterisk
        mt="md"
      />
      <TextInput
        label="Email"
        placeholder="Email"
        type="email"
        name="email"
        control={control}
        withAsterisk
        mt="md"
      />
      {/* If there are no categories, display message for creating one */}
      {tags?.length > 0 ? (
        <Select
          label="Category"
          placeholder="Category"
          name="categoryOfUser"
          control={control}
          withAsterisk
          mt="md"
          data={tags}
          allowDeselect={false}
        />
      ) : (
        <Text size="sm" c="dimmed" mt="sm">
          No categories available. Please create a category tag
          <Text component={Link} to="/app/tags" c="blue">
            {" "}
            here
          </Text>
          .
        </Text>
      )}

      <Button type="submit" mt="md" loading={isPending}>
        Create Ticket
      </Button>
    </form>
  );
};

export default CreateTicket;
