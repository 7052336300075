import { useApiQuery, useApiMutation } from "../services/react-query.js";
import { webConfig } from "../../config.js";
import { useMutation } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";

export const useGetAllTickets = (params) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["tickets", params],
    },
    fetchOptions: {
      url: "/tickets",
      method: "GET",
      params,
    },
  });
};

export const useCreateTicket = () => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["tickets"],
    },
    fetchOptions: {
      url: "/tickets",
      method: "POST",
    },
    successMessage: {
      title: "Ticket was created successfully",
    },
  });
};

export const useUpdateTicket = ({ ticketId }) => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["tickets"],
    },
    fetchOptions: {
      url: `/tickets/${ticketId}`,
      method: "PATCH",
    },
    successMessage: {
      title: "Ticket was updated successfully",
    },
  });
};

export const useGetTicket = ({ ticketId }) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["tickets", ticketId],
    },
    fetchOptions: {
      url: `/tickets/${ticketId}`,
      method: "GET",
    },
  });
};
export const useSendEmailToTickets = (params) => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["ticketEmail", params],
    },
    fetchOptions: {
      url: `/tickets/send-email`,
      method: "POST",
      body: params,
    },
    successMessage: {
      title: "Email was sent successfully",
    },
  });
};

export const useImportTickets = () => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["tickets"],
    },
    fetchOptions: {
      url: "/tickets/import",
      method: "POST",
    },
    successMessage: {
      title: "Tickets were imported successfully",
    },
  });
};

export const useGetTicketHistory = ({ eventId }) => {
  return useApiQuery({
    queryOptions: { queryKey: ["ticketHistory", eventId] },
    fetchOptions: {
      url: `/tickets/register/history?eventId=${eventId}`,
      method: "GET",
    },
  });
};

export const useGetTicketInfo = ({ eventId }) => {
  return useApiQuery({
    queryOptions: { queryKey: ["ticketInfo", eventId], enabled: !!eventId },
    fetchOptions: {
      url: `/tickets/register/summary?eventId=${eventId}`,
      method: "GET",
    },
  });
};

export const useGetTicketDownloadTemplate = () => {
  return useApiQuery({
    queryOptions: { queryKey: ["ticketDownloadTemplate"] },
    fetchOptions: {
      url: "/email/template/ticketDownload",
      method: "GET",
      params: {
        parsedEmailBody: "",
        baseApiUrl: webConfig.baseUrl,
        qrCode: "",
      },
    },
  });
};

export const useDeleteTicket = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeleteTicket" },
    fetchOptions: { url: `/tickets`, method: "DELETE" },
    invalidateQueryKey: "tickets",
    successMessage: { title: "Ticket has been deleted", color: "green" },
  });

export const useGetTicketColumns = ({ eventId }) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["columns"],
    },
    fetchOptions: {
      url: `/tickets/columns/${eventId}`,
      method: "GET",
    },
  });
};

export const useUpdateColumnVisibility = ({ eventId, columns }) => {
  return useApiMutation({
    queryOptions: { queryKey: ["columns"] },
    fetchOptions: {
      url: `/tickets/columns/${eventId}`,
      method: "PATCH",
      body: { columns },
    },
  });
};

export const useMakeCustomColumns = ({ eventId, columns }) => {
  return useApiMutation({
    invalidateQueryKey: "tickets",
    fetchOptions: {
      url: `/tickets/custom-columns/${eventId}`,
      method: "POST",
      body: { columns },
    },
  });
};

export const useGetCustomFields = ({ eventId, ticketId }) => {
  return useApiQuery({
    queryOptions: { queryKey: ["customFields"] },
    fetchOptions: {
      url: ticketId
        ? `/tickets/custom-columns/${eventId}?ticketId=${ticketId}`
        : `/tickets/custom-columns/${eventId}`,
      method: "GET",
    },
  });
};

export const useUpdateCustomFields = ({ eventId, ticketId, data }) => {
  return useApiMutation({
    fetchOptions: {
      url: `/tickets/custom-columns/${eventId}`,
      method: "PATCH",
      body: { ticketId, data },
    },
  });
};

export const useExportTickets = () => {
  return useMutation({
    queryKey: ["exportAttendees"],
    mutationFn: async ({ eventId }) => {
      const token = localStorage.getItem("token");
      const response = await fetch(`/api/tickets/export?eventId=${eventId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "text/csv",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to export tickets");
      }

      return response.blob().then((blob) => ({
        blob,
        eventId,
      }));
    },
    onSuccess: ({ blob, eventId }) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `congrs-tickets-${eventId}-${new Date().toDateString()}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);

      notifications.show({
        title: "Attendees exported successfully",
        color: "green",
      });
    },
    onError: () => {
      notifications.show({
        title: "Failed to export tickets",
        color: "red",
      });
    },
  });
};
