import { ActionIcon, Group, Image, Select, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { DataTable } from "../DataTable";
import { useDeleteSponsor } from "../../api/sponsors";
import deleteModal from "../deleteModal";

function SponsorsTable({
  data,
  isLoading,
  page,
  setPage,
  sortStatus,
  setSort,
  sponsorReps,
  filter,
}) {
  const { mutate, isSuccess, isError, error } = useDeleteSponsor();
  const uniqueLevels = [
    ...new Set(data?.data.map((sponsor) => sponsor.levelName)),
  ].map((level) => ({ value: level, label: level }));

  return (
    <>
      <DataTable
        records={data?.data}
        columns={[
          { accessor: "name", sortable: true },
          {
            accessor: "levelName",
            title: "Level",
            render: (record) =>
              record.levelName.charAt(0).toUpperCase() +
              record.levelName.slice(1),
            sortable: true,
            filter: (
              <Select
                label="Filter by sponsor level"
                placeholder="Select sponsor level"
                value={filter.levelFilter}
                onChange={(value) => filter.setFilter(value)}
                data={uniqueLevels}
                clearable
              />
            ),
          },
          {
            accessor: "representation",
            render: (record) => {
              const reps = sponsorReps
                ?.filter((rep) => rep.sponsorId === record.id)
                .map((rep) => {
                  const fullName = rep.firstName + " " + rep.lastName;
                  const occupation = rep.occupation || null;
                  return { fullName, occupation };
                });

              return reps?.map((rep, index) => (
                <div key={rep + index} style={{ marginBottom: 5 }}>
                  <Text key={rep} size="sm">
                    {rep.fullName}
                  </Text>
                  {rep.occupation && (
                    <Text key={rep.occupation} size="xs" c="gray">
                      {rep.occupation}
                    </Text>
                  )}
                </div>
              ));
            },
          },
          { accessor: "description" },
          {
            accessor: "logo",
            render: (record) => {
              if (record.logo) {
                return (
                  <Image
                    h={30}
                    w={30}
                    fit="contain"
                    src={record.logo}
                    style={{ justifySelf: "center" }}
                  />
                );
              }
              return;
            },
          },
          {
            accessor: "links",
            render: (sponsor) =>
              sponsor.links.map((link, index) => {
                return (
                  <Group key={link.url}>
                    <Text
                      size="sm"
                      style={{
                        textDecorationLine: "underline",
                      }}
                      component={Link}
                      to={
                        link?.url.startsWith("http://") ||
                        link?.url.startsWith("https://")
                          ? link?.url
                          : `//${link?.url}`
                      }
                      pb={10}
                    >
                      {link.label}
                    </Text>
                  </Group>
                );
              }),
          },
          {
            accessor: "actions",
            width: 100,
            render: (sponsor) => (
              <Group justify="center">
                <Tooltip label="Edit">
                  <ActionIcon
                    size="md"
                    variant="light"
                    component={Link}
                    to={`${sponsor.id}`}
                  >
                    <IconEdit />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                  <ActionIcon
                    size="md"
                    variant="light"
                    color="red"
                    onClick={() =>
                      deleteModal(
                        sponsor,
                        `delete '${sponsor.name}' sponsor`,
                        mutate,
                        isSuccess,
                        isError,
                        error
                      )
                    }
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        page={page}
        onPageChange={setPage}
        recordsPerPage={data?.pagination.perPage}
        totalRecords={data?.pagination.total}
        fetching={isLoading}
        sortStatus={sortStatus}
        onSortStatusChange={setSort}
      />
    </>
  );
}

export default SponsorsTable;
