import { useContext, useState } from "react";
import { Title, Group, Flex, ActionIcon, Button, Tooltip } from "@mantine/core";
import { IconEdit, IconMapPlus, IconTrash } from "@tabler/icons-react";

import { getCurrentWorkspace } from "../../../services/userConfig";
import { AuthContext } from "../../../services/context";
import {
  useCreateEventLocation,
  useGetEventLocations,
  usePatchEventLocation,
} from "../../../api/event";
import {
  openDeleteLocationModal,
  openEditLocationModal,
} from "./LocationModals";
import { InlineEditableTable } from "../../InlineEditableTable";

function Locations() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const { data, isPending } = useGetEventLocations(eventId);

  const { mutate: createLocation } = useCreateEventLocation(eventId);
  // Keep track of the location being edited to use as param for the update mutation
  const [editingLocationId, setEditingLocationId] = useState(null);
  const { mutate: updateLocation } = usePatchEventLocation(
    eventId,
    editingLocationId
  );

  return (
    <Flex direction="column">
      <InlineEditableTable
        renderHeader={(addNewRow, isAddingNewRow) => (
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            mt="sm"
            mb="xs"
          >
            <Title order={2}>Locations</Title>
            <Button
              size="sm"
              variant="light"
              color="blue"
              onClick={() => {
                addNewRow();
              }}
              leftSection={<IconMapPlus />}
              disabled={isAddingNewRow}
            >
              Add location
            </Button>
          </Flex>
        )}
        data={data}
        fetching={isPending}
        columns={[
          {
            accessor: "name",
            editable: true,
            inputProps: { placeholder: "Name" },
          },
          {
            accessor: "sessionCount",
            editable: false,
            width: "0%",
            title: "Sessions",
          },
          {
            accessor: "actions",
            width: "0%",
            render: (row) => {
              return (
                <Group wrap="noWrap" justify="right">
                  <ActionIcon
                    size="md"
                    color="blue"
                    variant="light"
                    onClick={() => {
                      openEditLocationModal(eventId, row.id);
                    }}
                  >
                    <IconEdit />
                  </ActionIcon>

                  <Tooltip
                    label={
                      row.sessionCount > 0
                        ? "Location cannot be deleted because it is used in sessions"
                        : "Delete location"
                    }
                    w={200}
                    multiline
                  >
                    <ActionIcon
                      size="md"
                      color="red"
                      variant="light"
                      onClick={() => {
                        openDeleteLocationModal(eventId, row.id);
                      }}
                      disabled={row.sessionCount > 0}
                    >
                      <IconTrash />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              );
            },
            editable: false,
          },
        ]}
        onRowChange={(row) => {
          setEditingLocationId(row.id);
          const oldData = data.find((r) => r.id === row.id);

          // Find the differences between the old and new data
          const changes = Object.keys(row).reduce((acc, key) => {
            if (row[key] !== oldData[key]) {
              acc[key] = row[key];
            }
            return acc;
          }, {});

          // Save the changes to the server
          updateLocation({ id: row.id, ...changes });
        }}
        onRowCreate={(row) => {
          createLocation(row);
        }}
        validateRow={(row) => {
          const errors = {};
          if (!row.name) {
            errors.name = "Name is required";
          }
          return errors;
        }}
        createEmptyRow={() => ({
          name: "",
        })}
      />
    </Flex>
  );
}

export default Locations;
