import { useState } from "react";
import { Controller } from "react-hook-form";
import {
  Group,
  Avatar,
  FileButton,
  Button,
  LoadingOverlay,
  Flex,
  Text,
  Tooltip,
  Image,
} from "@mantine/core";

import { useFileUpload } from "../services/react-query";
import { cropImage } from "./ImageCropModal";
import { modals } from "@mantine/modals";

const ImageUpload = ({
  control,
  name,
  label,
  description,
  defaultImage,
  resize = false,
  aspectRatio = 1,
  ...props
}) => {
  const [preview, setPreview] = useState(defaultImage);

  const { isPending, mutateAsync } = useFileUpload();

  const handleImageUpload = async (file, onChange) => {
    if (!file) return;

    if (resize) {
      const croppedImage = await cropImage(file, aspectRatio);
      if (!croppedImage) return;
      file = croppedImage;
    }

    const previewUrl = URL.createObjectURL(file);
    setPreview(previewUrl);

    const data = await mutateAsync(file);
    onChange(data.fileKey);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Flex direction="column" {...props}>
          {label && (
            <Text size="sm" fw={600} mb={description ? 0 : "sm"}>
              {label}
            </Text>
          )}
          {description && (
            <Text size="xs" c="dimmed" style={{ marginBottom: 10 }}>
              {description}
            </Text>
          )}
          <Group>
            <Flex pos="relative">
              <LoadingOverlay visible={isPending} />
              <Tooltip label="Click to see preview" withArrow>
                <Avatar
                  src={preview}
                  alt="avatar"
                  radius="300"
                  size="xl"
                  onClick={() => {
                    modals.open({
                      title: "Image preview",
                      size: "lg",
                      children: (
                        <Flex>
                          <Image src={preview} alt="avatar" />
                        </Flex>
                      ),
                    });
                  }}
                />
              </Tooltip>
            </Flex>

            <FileButton
              onChange={(payload) => handleImageUpload(payload, onChange)}
              accept="image/png,image/jpeg"
            >
              {(props) => (
                <Button size="compact-md" variant="light" {...props}>
                  Upload picture
                </Button>
              )}
            </FileButton>
          </Group>
        </Flex>
      )}
    />
  );
};

export default ImageUpload;
