import { useDataTableColumns } from "mantine-datatable";
import {
  Button,
  Group,
  Paper,
  Text,
  Container,
  Title,
  Stack,
  Loader,
  Center,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useState } from "react";

import { DataTable } from "../../DataTable";

function ColumnVisibility({
  currentEventId,
  visibleColumns,
  setVisibleColumns,
  updateColumnsVisibility,
  updateColumnsVisibilitySuccess,
  customColumns,
  allColumns,
}) {
  const { effectiveColumns, resetColumnsToggle, columnsToggle } =
    useDataTableColumns({
      key: "settings",
      columns: allColumns.map((column) => ({
        ...column,
        isVisible: visibleColumns.some(
          (visibleColumn) => visibleColumn.accessor === column.accessor
        ),
      })),
    });

  // Track loading state
  const [loading, setLoading] = useState(false);

  // Function to reset columns visibility to default
  const resetColumnsVisibility = () => {
    setVisibleColumns(
      allColumns.map((column) => ({ ...column, isVisible: true }))
    );
    resetColumnsToggle();

    // Save this change so that it persists
    updateColumnsVisibility({
      eventId: currentEventId,
      columns: allColumns.map((column) => ({
        name: column.accessor,
        isVisible: true,
      })),
    });

    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  // Create example data for the table
  const exampleData = setExampleData(customColumns);

  return (
    <Stack>
      <Title order={4}>Column visibility</Title>
      <Paper shadow="md" p="sm" withBorder mb="xs" w={"70%"}>
        <Container
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconInfoCircle />
          <Container style={{ marginLeft: 10 }}>
            <Text style={{ fontSize: 13 }}>
              Configure the columns you want to see in the tickets table.
            </Text>
            <Text style={{ fontSize: 13 }}>
              Remove columns by pressing the X button in column header and click
              SAVE to save changes.
            </Text>
            <Text style={{ fontSize: 13 }}>
              You cannot hide columns required for ticket creation ( First Name,
              Last Name, Email ).
            </Text>
          </Container>
        </Container>
      </Paper>

      {loading ? (
        // Show Loader if data is being refetched
        <Center>
          <Loader size="md" />
        </Center>
      ) : (
        effectiveColumns &&
        effectiveColumns.length > 0 && (
          <DataTable
            records={exampleData}
            columns={effectiveColumns}
            storeColumnsKey={"settings"}
            page={1}
            onPageChange={() => {}}
            totalRecords={exampleData.length}
            recordsPerPage={5}
            style={{ width: "100%" }}
          />
        )
      )}

      <Group justify="right" mt={10}>
        <Button
          onClick={resetColumnsVisibility}
          disabled={visibleColumns.length === allColumns.length}
        >
          Reset removing columns
        </Button>
        <Button
          disabled={
            columnsToggle.every((column) => column.toggled) ||
            updateColumnsVisibilitySuccess
          }
          onClick={() => {
            updateColumnsVisibility({
              eventId: currentEventId,
              columns: columnsToggle.map((column) => ({
                name: column.accessor,
                isVisible: column.toggled,
              })),
            });
          }}
        >
          Save column visibility settings
        </Button>
      </Group>
    </Stack>
  );
}

// Function to set example data for the table
const setExampleData = (customColumns) => {
  const exampleData = [
    {
      id: 1,
      firstName: "John",
      lastName: "Doe",
      email: "john@mail.com",
      status: "new",
      category: "attendee",
      paid: true,
      amountPaid: 100,
      currency: "USD",
      isApproved: true,
    },
    {
      id: 2,
      firstName: "Mary",
      lastName: "Doe",
      email: "mary@mail.com",
      status: "new",
      category: "speaker",
      paid: false,
      amountPaid: 0,
      currency: "USD",
      isApproved: false,
    },
  ];

  // If there are custom columns, add custom data for those columns
  if (customColumns.length) {
    exampleData.forEach((data) => {
      customColumns.forEach((column) => {
        data[column.name] = "custom data";
      });
    });
  }

  return exampleData;
};

export default ColumnVisibility;
