import { useEffect, useState } from "react";
import { Avatar, Badge, Group, Switch, Stack } from "@mantine/core";
import { useContext } from "react";

import { AuthContext } from "../../../services/context";
import { getCurrentWorkspace } from "../../../services/userConfig";
import {
  useGetTicketColumns,
  useUpdateColumnVisibility,
  useMakeCustomColumns,
} from "../../../api/tickets";
import { CurrencyInput } from "../CurrencyInput";
import ColumnVisibility from "./ColumnVisibility";
import CustomColumns from "./CustomColumns";

const defaultTicketColumns = [
  "picture",
  "firstName",
  "lastName",
  "email",
  "status",
  "category",
  "paid",
  "amountPaid",
  "isApproved",
]; // Default ticket columns

function TableSettings() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const { data: columns } = useGetTicketColumns({
    eventId: currentEventId,
  });

  // Function to update all columns visibility
  const {
    mutate: updateColumnsVisibility,
    isSuccess: updateColumnsVisibilitySuccess,
  } = useUpdateColumnVisibility({
    eventId: currentEventId,
  });

  // Function to update custom columns names
  const { mutate: makeCustomColumns, isSuccess: makeCustomColumnsSuccess } =
    useMakeCustomColumns({
      eventId: currentEventId,
    });

  const [allColumns, setAllColumns] = useState([]); // All columns from the database
  const [visibleColumns, setVisibleColumns] = useState([]); // Visible columns
  const [customColumns, setCustomColumns] = useState([]); // Custom columns (added by user)

  useEffect(() => {
    if (columns) {
      const visible = columns.filter((column) => column.isVisible === true);

      // Map columns to add toggleable property and render function
      const allColumnsWithProps = addRenderAndToggleable(columns);
      const visibleColumnsWithProps = addRenderAndToggleable(visible);

      setAllColumns(allColumnsWithProps);
      setVisibleColumns(visibleColumnsWithProps);
    }
  }, [columns, updateColumnsVisibilitySuccess]);

  return (
    <Stack>
      <CustomColumns
        currentEventId={currentEventId}
        columns={columns}
        defaultTicketColumns={defaultTicketColumns}
        customColumns={customColumns}
        setCustomColumns={setCustomColumns}
        updateColumnsVisibility={updateColumnsVisibility}
        makeCustomColumns={makeCustomColumns}
        makeCustomColumnsSuccess={makeCustomColumnsSuccess}
      />
      <ColumnVisibility
        currentEventId={currentEventId}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        updateColumnsVisibility={updateColumnsVisibility}
        updateColumnsVisibilitySuccess={updateColumnsVisibilitySuccess}
        customColumns={customColumns}
        allColumns={allColumns}
      />
    </Stack>
  );
}

// Function to add custom render function and toggleable property to all columns
// to all columns except required fields for creating an ticket
const addRenderAndToggleable = (columns) => {
  return columns.map((column) => {
    switch (column.name) {
      case "picture":
        return {
          accessor: column.name,
          toggleable: true,
          render: (column) => (
            <Group justify="center">
              <Avatar
                src={column.picture}
                size="md"
                radius="sm"
                alt="avatar"
                color="initials"
                name={`${column.firstName} ${column.lastName}`}
              />
            </Group>
          ),
        };
      case "paid":
        return {
          accessor: column.name,
          toggleable: true,
          render: (column) => (
            <Group justify="center">
              <Switch checked={column.paid} disabled justify={"center"} />
            </Group>
          ),
        };
      case "isApproved":
        return {
          accessor: column.name,
          title: "Approve",
          toggleable: true,
          render: (column) => (
            <Group justify="center">
              <Switch checked={column.isApproved} disabled />
            </Group>
          ),
        };
      case "amountPaid":
        return {
          accessor: column.name,
          toggleable: true,
          render: (column) => (
            <CurrencyInput
              amountValue={column.amountPaid}
              ticketId={column.id}
              disabled={true}
            />
          ),
        };
      case "status":
        return {
          accessor: column.name,
          toggleable: true,
          render: (column) => (
            <Group justify="center">
              <Badge color={"gray"}>{column.status}</Badge>
            </Group>
          ),
        };
      case "category":
        return {
          accessor: column.name,
          toggleable: true,
          render: (column) => (
            <Group justify="center">
              <Badge color={"gray"}>{column.category}</Badge>
            </Group>
          ),
        };
      default:
        return {
          accessor: column.name,
          toggleable: ["firstName", "lastName", "email"].includes(column.name)
            ? false
            : true,
        };
    }
  });
};

export default TableSettings;
