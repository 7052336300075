import { createContext, useContext, useEffect, useState, useRef } from "react";

const ActionButtonsContext = createContext();

export function ActionButtonsProvider({ children }) {
  const [actionButtons, setActionButtons] = useState([]);

  return (
    <ActionButtonsContext.Provider value={{ actionButtons, setActionButtons }}>
      {children}
    </ActionButtonsContext.Provider>
  );
}

export function useActionButtons() {
  return useContext(ActionButtonsContext);
}

/**
 * A hook to set action buttons in a global context
 * @param {Array<{label: string, icon: JSX.Element, onClick: () => void, navigate: string}>} actions - Array of action button configurations
 * @returns {void}
 * @description Sets action buttons in the global context and cleans up on unmount
 * @example
 * const actions = [
 *   {
 *     label: "Add",
 *     icon: <PlusIcon />,
 *     onClick: () => console.log("clicked")
 *   }
 * ];
 * useSetActionButtons(actions);
 */
export function useSetActionButtons(rawActions) {
  const { setActionButtons } = useActionButtons();

  // Create a ref to store the latest actions.
  // Ref is a state that keeps its value between renders.
  // And also it doesn't trigger a re-render when it changes.
  const actionsRef = useRef(rawActions);

  // Update ref every time when actions change
  // This is needed to keep the latest actions in the ref
  useEffect(() => {
    actionsRef.current = rawActions;
  }, [rawActions]);

  // Sets up the action buttons only once
  // This effect runs only when setActionButtons function changes (rarely)
  useEffect(() => {
    // Create a new actions array with wrapped onClick functions
    const wrappedActions = actionsRef.current.map((action) => ({
      ...action,
      // create new onClick handler for each action
      onClick: (...args) => {
        // When clicked, find the latest version of this action
        // We match actions by label
        const currentAction = actionsRef.current.find(
          (a) => a.label === action.label
        );
        // Call the onClick function of the latest action
        currentAction?.onClick?.(...args);
      },
    }));

    // Set the wrapped actions in the context
    setActionButtons(wrappedActions);

    // Clear actions when we unmount this component
    return () => setActionButtons([]);
  }, [setActionButtons]);
}
