import { IconCheck, IconCopy } from "@tabler/icons-react";
import { Button, Tooltip } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";

export function ButtonCopy({ link, ...props }) {
  const clipboard = useClipboard();
  return (
    <Tooltip
      label="Link copied!"
      offset={5}
      position="bottom"
      radius="xl"
      transitionProps={{ duration: 100, transition: "slide-down" }}
      opened={clipboard.copied}
    >
      <Button
        variant="light"
        rightSection={
          clipboard.copied ? (
            <IconCheck size={20} stroke={1.5} />
          ) : (
            <IconCopy size={20} stroke={1.5} />
          )
        }
        radius="xl"
        size="md"
        pr={14}
        h={48}
        styles={{ section: { marginLeft: 22 } }}
        onClick={() => clipboard.copy(link)}
        {...props}
      >
        Copy link to clipboard
      </Button>
    </Tooltip>
  );
}
