// Helper function that converts file to base64
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const reduceWhiteSpaces = (string) => {
  return string.trim().replace(/\s\s+/g, " ");
};

export const ensureNoTrailingSlashAtEndOfUrl = (url) => {
  return url.replace(/\/+$/, "");
};

export const removeIdsFromUrl = (url) => {
  return url.replace(/\/\d+/g, "");
};

export const appendSearchParams = (url, params) => {
  // If one of the params is null, remove it
  // So that the URLSearchParams doesn't append it as a string
  // For example, /users?role=null
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });

  // Convert objects to strings
  // For example,
  // /users?sort={"columnAccessor":"firstName","direction":"asc"}
  Object.keys(params).forEach((key) => {
    if (typeof params[key] === "object") {
      params[key] = JSON.stringify(params[key]);
    }
  });

  const searchParams = new URLSearchParams(params);

  return `${url}?${searchParams.toString()}`;
};
