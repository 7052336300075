import {
  IconBrush,
  IconTags,
  IconCubeUnfolded,
  IconMessageChatbot,
  IconTimelineEvent,
  IconUsersGroup,
  IconCalendar,
  IconVersions,
  IconNotebook,
  IconMessage2,
  IconSpeakerphone,
  IconStar,
  IconTicket,
  IconHistoryToggle,
} from "@tabler/icons-react";

const links = [
  {
    path: "/app/workspaces",
    label: "Workspaces",
    icon: IconCubeUnfolded,
  },
  {
    path: "/app/events",
    label: "Events",
    icon: IconCalendar,
  },
  {
    path: "/app/users",
    label: "Users",
    icon: IconUsersGroup,
    roles: ["sysAdmin"],
  },
  {
    path: "/app/tickets",
    label: "Tickets",
    icon: IconTicket,
  },
  {
    path: "/app/tickets/history",
    label: "Ticket History",
    icon: IconHistoryToggle,
  },
  {
    path: "/app/events/customize",
    label: "Customization",
    icon: IconBrush,
  },
  {
    path: "/app/tags",
    label: "Tags",
    icon: IconTags,
  },
  {
    path: "/app/sessions",
    label: "Sessions",
    icon: IconTimelineEvent,
  },
  {
    path: "/app/speakers",
    label: "Speakers",
    icon: IconSpeakerphone,
  },
  {
    path: "/app/polls",
    label: "Polls",
    icon: IconMessageChatbot,
  },
  {
    path: "/app/sponsors",
    label: "Sponsors",
    icon: IconVersions,
  },
  {
    path: "/app/pages",
    label: "Pages",
    icon: IconNotebook,
  },
  {
    path: "/app/announcements",
    label: "Announcements",
    icon: IconMessage2,
  },
  {
    path: "/app/special-invitation",
    label: "Special Invitation",
    icon: IconStar,
  },
];

export const filteredLinks = (role) => {
  return links.filter((link) => {
    if (link.roles) {
      return link.roles.includes(role);
    }
    return true;
  });
};

export default links;
