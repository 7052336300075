import { useState } from "react";
import Cropper from "react-easy-crop";
import { Container, Group, Button, Flex } from "@mantine/core";
import { modals } from "@mantine/modals";

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });
};
const ImageCropModal = ({ imageFile, aspectRatio = 1, onSave }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (_, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  };

  const handleSave = async () => {
    try {
      const croppedImage = await getCroppedImg(
        URL.createObjectURL(imageFile),
        croppedAreaPixels
      );
      onSave(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Group style={{ flexDirection: "row", gap: 5, justifyContent: "center" }}>
        <Container
          style={{
            width: 500,
            height: 281,
            position: "relative",
          }}
        >
          <Cropper
            image={URL.createObjectURL(imageFile)}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Container>
        <Container
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
          />
        </Container>

        <Flex
          direction="column"
          style={{
            gap: 10,
            maxHeight: 300,
          }}
        >
          <Group gap={10}>
            <Button onClick={handleSave}>Save</Button>
          </Group>
        </Flex>
      </Group>
    </>
  );
};

export const cropImage = (imageFile, aspectRatio) => {
  return new Promise((resolve) => {
    modals.open({
      title: "Crop image",
      size: "lg",
      children: (
        <ImageCropModal
          imageFile={imageFile}
          aspectRatio={aspectRatio}
          onSave={(croppedImage) => {
            resolve(croppedImage);
            modals.closeAll();
          }}
        />
      ),
    });
  });
};
