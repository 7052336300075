import React from "react";
import { useGetTicketInfo } from "../../api/tickets";
import { Flex, Text, Group, Paper, Title } from "@mantine/core";
import RoleBadges from "../RoleBadges";

function TicketHistoryDashboard({ eventId }) {
  const { data: historyInfo } = useGetTicketInfo({ eventId });

  return (
    <Paper p="lg" shadow="xs" radius="md" style={{ marginBottom: 20 }}>
      <Title order={3} style={{ marginBottom: 10 }}>
        Tickets overview
      </Title>

      <Group grow wrap preventGrowOverflow="false">
        <Flex direction="column" align="center">
          <Text size="md" c="dimmed" fw="500">
            Tickets on event
          </Text>
          <Text size="72px" fw="700">
            {historyInfo?.totalTickets || 0}
          </Text>
        </Flex>

        <Flex direction="column" align="center" gap="xs">
          <Text size="md" c="dimmed" fw="500">
            Changes made by
          </Text>

          {historyInfo?.admins?.map((admin) => (
            <Flex gap="xs">
              <Text size="sm" fw="500">
                {admin?.firstName} {admin?.lastName}
              </Text>
              <RoleBadges roleName={admin?.role} />
            </Flex>
          ))}
        </Flex>

        <Flex direction="column" align="center">
          <Text size="md" c="green" fw="500">
            Approved Tickets
          </Text>
          <Text size="72px" fw="700">
            {historyInfo?.approvedTickets || 0}
          </Text>
        </Flex>
      </Group>
    </Paper>
  );
}

export default TicketHistoryDashboard;
