import { DataTable as MantineDataTable } from "mantine-datatable";

/**
 * Renders a data table with default styles and settings.
 *
 * @param {import("mantine-datatable").DataTableProps} props - Additional props passed to the MantineDataTable component.
 * @returns {JSX.Element} A styled data table component.
 */
export function DataTable({ columns, resizable, ...props }) {
  const visibleColumnsLength = columns.filter(
    (column) => !column.hidden
  ).length;
  const columnsWithResizableProp = columns.map((column) => ({
    ...column,
    resizable:
      visibleColumnsLength > 3 && visibleColumnsLength < 10 && resizable,
    ellipsis: true,
  }));

  return (
    <MantineDataTable
      withTableBorder
      striped
      withColumnBorders
      highlightOnHover
      minHeight={180}
      columns={columnsWithResizableProp}
      storeColumnsKey="data-table-columns"
      noRecordsText="No results found"
      {...props}
    />
  );
}
