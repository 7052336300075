import { useEffect, useState } from "react";
import {
  Text,
  Group,
  Button,
  rem,
  Container,
  Title,
  Paper,
  List,
} from "@mantine/core";
import { IconFile } from "@tabler/icons-react";
import { useContext } from "react";

import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useImportTickets } from "../../api/tickets";
import { toBase64 } from "../../services/helper";
import CSVDropzoneButton from "../CSVDropzone";

function ImportTicket() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const [file, setFile] = useState(null);

  const { mutate, isPending, data, isSuccess, reset } = useImportTickets();

  const importData = {
    total: data?.total,
    successful: data?.successfullyInserted,
    errors: {
      count:
        data?.errors?.insertErrors?.length +
        data?.errors?.validationErrors?.length,
    },
  };

  // TODO: Remove this console.log
  // This is just for debugging purposes
  useEffect(() => {
    if (
      data?.errors?.insertErrors?.length +
        data?.errors?.validationErrors?.length >
      0
    ) {
      console.log("INSERT ERRORS");
      console.log(data.errors.insertErrors);

      console.log("VALIDATION ERRORS");
      console.log(data.errors.validationErrors);
    }
  }, [data]);

  const handleSubmit = async () => {
    const base64file = await toBase64(file);

    const data = {
      file: {
        name: file.name,
        type: file.type,
        data: base64file,
      },
      eventId: currentEventId,
    };

    mutate(data);
  };

  return (
    <Container>
      <Title>Import tickets</Title>
      <Paper withBorder shadow="xs" p="lg" mt="sm">
        <Container
          bg="var(--mantine-color-gray-light)"
          style={{ borderRadius: 24 }}
          p="sm"
          mb="lg"
        >
          <Text fw={700} size="lg">
            File instructions:
          </Text>
          <List size="sm">
            <List.Item>File must be in .csv or .xlsx format</List.Item>
            <List.Item>File size must be less than 10mb</List.Item>
            <List.Item>
              File must contain columns: first name, last name, email
            </List.Item>
            <List.Item>
              Optional columns:
              <List withPadding size="sm">
                <List.Item>
                  category [attendee/speaker/sponsor/organizer]
                </List.Item>
              </List>
            </List.Item>
          </List>
        </Container>

        {!file && <CSVDropzoneButton onDrop={setFile} />}

        {file && (
          <>
            <IconFile style={{ width: rem(50), height: rem(50) }} />
            <Text mt={8}>{file.name}</Text>
            <Group mt={16} justify="flex-start">
              <Button
                onClick={handleSubmit}
                style={{ marginTop: rem(20) }}
                color="blue"
                disabled={!file || isSuccess}
                loading={isPending}
              >
                Submit
              </Button>
              <Button
                onClick={() => {
                  setFile(null);
                  reset();
                }}
                style={{ marginTop: rem(20) }}
                color="red"
                disabled={isPending}
              >
                Clear file
              </Button>
            </Group>
          </>
        )}
        {data && isSuccess && file && (
          <Text mt={20} c="green">
            Successfully uploaded. Imported {importData.successful} tickets out
            of {importData.total}.
            <Text c="dimmed">
              {" "}
              {importData.errors.count} tickets could not be imported. See
              console for more details.
            </Text>
          </Text>
        )}
      </Paper>
    </Container>
  );
}

export default ImportTicket;
