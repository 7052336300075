import { Switch } from "@mantine/core";
import { useState } from "react";
import { useUpdateTicket } from "../../api/tickets";

function TicketSwitch({ checked, ticketId, columnName }) {
  const [isChecked, setIsChecked] = useState(checked);

  const { mutate } = useUpdateTicket({ ticketId });

  if (!columnName) {
    return null;
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    mutate(
      { [columnName]: !isChecked },
      { onError: () => setIsChecked(isChecked) }
    );
  };

  return (
    <Switch
      color="green"
      checked={isChecked}
      onChange={() => {
        handleCheckboxChange();
      }}
    />
  );
}

export default TicketSwitch;
