import PagesTable from "../components/Pages/PagesTable";
import { useGetPages } from "../api/pages";
import { useContext } from "react";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useState } from "react";
import { useSetActionButtons } from "../services/ActionButtonsContext";
import { IconPlus } from "@tabler/icons-react";

function Pages() {
  useSetActionButtons([
    {
      label: "Create",
      icon: <IconPlus />,
      navigate: "./pages/create",
    },
  ]);

  const { user } = useContext(AuthContext);

  const currentWorkspace = getCurrentWorkspace(user);

  const eventId = currentWorkspace.eventId;

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data } = useGetPages({ eventId, ...pagination });
  return (
    <PagesTable data={data} pagination={{ data: pagination, setPagination }} />
  );
}

export default Pages;
