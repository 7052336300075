import {
  Button,
  Group,
  Menu,
  ActionIcon,
  useMantineTheme,
  rem,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import classes from "./ActionButtons.module.css";

import { useMediaQuery } from "@mantine/hooks";
import { useActionButtons } from "../../services/ActionButtonsContext";
import { useNavigate } from "react-router-dom";

const ActionButtons = () => {
  const theme = useMantineTheme();
  const { actionButtons: actions } = useActionButtons();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const router = useNavigate();

  if (!actions?.length) return null;

  if (actions.length === 1) return <ActionButton {...actions[0]} />;

  if (!isMobile && actions.length <= 3) {
    return (
      <div style={{ justifyItems: "flex-end", flexWrap: "nowrap" }}>
        {actions.map((action, index) => (
          <ActionButton key={`action-${index}`} {...action} ml="sm" />
        ))}
      </div>
    );
  }

  return (
    <Group wrap="nowrap" gap={0}>
      <ActionButton
        {...actions[actions.length - 1]}
        className={classes.button}
      />
      <Menu
        transitionProps={{ transition: "pop" }}
        position="bottom-end"
        withinPortal
        width={200}
      >
        <Menu.Target>
          <ActionIcon
            variant="filled"
            color={theme.primaryColor}
            size={36}
            className={classes.menuControl}
          >
            <IconChevronDown
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {actions.map((action, index) => {
            const isLast = index === actions?.length - 1;

            if (isLast) return null;

            return (
              <Menu.Item
                key={`action-${index}`}
                leftSection={action.icon}
                onClick={() => {
                  if (action.navigate) {
                    router(action.navigate);
                    return;
                  }
                  action.onClick?.();
                }}
              >
                {action.label}
              </Menu.Item>
            );
          })}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

const ActionButton = ({ label, icon, onClick, navigate, ...props }) => {
  const router = useNavigate();

  const handleClick = () => {
    if (navigate) {
      router(navigate);
      return;
    }
    onClick?.();
  };

  return (
    <Button {...props} onClick={handleClick} leftSection={icon}>
      {label}
    </Button>
  );
};

export default ActionButtons;
