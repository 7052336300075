import { Container, Paper, Text, Title, Button } from "@mantine/core";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { Textarea, TextInput } from "react-hook-form-mantine";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useCreateSpeaker } from "../../api/speakers";
import ImageUpload from "../ImageUpload";

function Create() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const defaultValues = {
    firstName: "",
    lastName: "",
    occupation: "",
    biography: "",
    eventId,
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues,
  });

  const { mutate, isPending } = useCreateSpeaker();

  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: () => {
        reset(defaultValues);
      },
    });
  };

  return (
    <Container w={600}>
      <Title order={1}>Create a new speaker</Title>
      <Text c="dimmed">
        Fill out the form below to create a new speaker. Speakers are a
        placeholder data for now. When you create a speaker, you can assign them
        to a session. When you assign an userId to a speaker, the speaker will
        be clickable and will redirect to the user profile.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="sm" px="sm" py="md" style={{ marginTop: 20 }}>
          <ImageUpload
            control={control}
            name="picture"
            label="Profile picture"
            description="Upload a profile picture for the speaker. The picture should be
            square and it should not be larger than 5MB."
            mb="sm"
            resize
          />

          <TextInput
            label="First Name"
            placeholder="John"
            name="firstName"
            control={control}
            required
          />
          <TextInput
            label="Last Name"
            placeholder="Doe"
            name="lastName"
            control={control}
            required
            mt="sm"
          />
          <TextInput
            label="Occupation"
            placeholder="Software Engineer at RoundCube"
            name="occupation"
            control={control}
            mt="sm"
          />
          <Textarea
            label="Biography"
            placeholder="Tell us about the speaker"
            name="biography"
            control={control}
            rows={3}
            mt="sm"
          />

          <Button
            type="submit"
            color="blue"
            style={{ marginTop: 20 }}
            loading={isPending}
          >
            Create speaker
          </Button>
        </Paper>
      </form>
    </Container>
  );
}

export default Create;
