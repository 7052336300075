import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, ColorInput, Flex, Paper, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Checkbox, Select, TextInput } from "react-hook-form-mantine";

import { usePatchEvent } from "../../../api/event";
import { colorSwatches } from "./utils/colorUtils";
import { fullFormats } from "ajv-formats/dist/formats";
import { extractDirtyData } from "../../../utils/dirtyData";
import ImageUpload from "../../ImageUpload";

function CustomizeEvent({ data, isSysAdmin }) {
  const { config } = data;
  const { logoImage, headerImage, accentColor } = config || {};
  const name = data?.name;
  const status = data?.status;
  const [color, setColor] = useState(accentColor ? accentColor : "#102694");

  const { mutate, isSuccess, isPending: isLoading } = usePatchEvent(data?.id);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm({
    defaultValues: {
      name,
      status,
      logoImage: logoImage,
      headerImage: headerImage,
      accentColor: color,
      mainLocation: data?.mainLocation || "",
      contactEmail: data?.contactEmail || "",
      isPublic: data?.isPublic || false,
    },
    resolver: ajvResolver(
      {
        type: "object",
        properties: {
          name: { type: "string", minLength: 1 },
          mainLocation: { type: "string" },
          status: { type: "string", enum: ["draft", "active", "closed"] },
          contactEmail: {
            anyOf: [
              { type: "string", format: "email" },
              { type: "string", maxLength: 0 },
            ],
          },
        },
        required: ["name"],
      },
      { formats: fullFormats }
    ),
  });

  useEffect(() => {
    reset({
      name,
      status,
      logoImage,
      headerImage,
      accentColor,
      mainLocation: data?.mainLocation || "",
      contactEmail: data?.contactEmail || "",
      isPublic: data?.isPublic || false,
    });
  }, [reset, data]);

  const onSubmit = (data) => {
    data.config = { ...config };

    const dirtyData = extractDirtyData(data, dirtyFields);

    if (dirtyData.accentColor) {
      dirtyData.config = { accentColor: dirtyData.accentColor };
      delete dirtyData.accentColor;
    }

    if (dirtyFields.logoImage) {
      dirtyData.config = {
        ...dirtyData.config,
        logoImage: dirtyData.logoImage,
      };
      delete dirtyData.logoImage;
    }

    if (dirtyFields.headerImage) {
      dirtyData.config = {
        ...dirtyData.config,
        headerImage: dirtyData.headerImage,
      };
      delete dirtyData.headerImage;
    }

    mutate(dirtyData);
  };

  // If user is not a system admin,
  // disable previous status, because they can
  // update status in order 'draft' -> 'active' -> 'closed'
  const statusOptions = [
    {
      label: "Draft",
      value: "draft",
      disabled: !isSysAdmin && data.status !== "draft",
    },
    {
      label: "Active",
      value: "active",
      disabled: !isSysAdmin && data.status === "closed",
    },
    { label: "Closed", value: "closed" },
  ];

  return (
    <Flex direction="column" flex="1" miw={{ lg: 500, sm: 300 }}>
      <Title order={2} mt="sm" mb="xs">
        General settings
      </Title>
      <Paper withBorder shadow="md" p={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Event title"
            type="text"
            name="name"
            defaultValue={name}
            control={control}
            withAsterisk
            mt="md"
          />

          <Select
            label="Status"
            placeholder="Select status"
            name="status"
            data={statusOptions}
            control={control}
            mt="md"
            allowDeselect={false}
          />

          <TextInput
            label="Main location"
            type="text"
            name="mainLocation"
            defaultValue={data?.mainLocation}
            control={control}
            mt="md"
            placeholder="Enter the main location of the event (e.g. Sava Centar)"
          />

          <TextInput
            label="Contact email"
            type="text"
            name="contactEmail"
            control={control}
            mt="md"
            placeholder="Enter the contact email for the event"
          />

          <ImageUpload
            control={control}
            name="logoImage"
            label="Logo image"
            description="Logo that is shown on the homepage of the application."
            defaultImage={logoImage}
            mt="md"
            resize
          />

          <ImageUpload
            control={control}
            name="headerImage"
            label="Header image"
            description="Image shown at the top of the mobile home page."
            defaultImage={headerImage}
            mt="md"
            resize
            aspectRatio={16 / 9}
          />

          <ColorInput
            label="Accent color"
            name="accentColor"
            control={control}
            value={color}
            my={10}
            withEyeDropper={false}
            withPicker={false}
            swatches={colorSwatches}
            swatchesPerRow={6}
            disallowInput
            closeOnColorSwatchClick
            onChange={(value) => {
              setColor(value);
              setValue("accentColor", value, { shouldDirty: true });
            }}
          />

          <Checkbox
            label="Generate public links"
            description="Links that are accessible to anyone, to see sessions for specific location / dates."
            name="isPublic"
            control={control}
            mt="md"
          />

          <Button
            type="submit"
            mt="md"
            loading={isLoading}
            disabled={!isDirty || isSuccess}
          >
            Save event config
          </Button>
        </form>
      </Paper>
    </Flex>
  );
}

export default CustomizeEvent;
