import { useEffect, useState } from "react";
import {
  Title,
  Paper,
  Group,
  TextInput,
  Fieldset,
  Container,
  Text,
  Switch,
  Button,
  Stack,
} from "@mantine/core";
import { IconInfoCircle, IconX, IconPlus } from "@tabler/icons-react";
import { closeModal, openConfirmModal } from "@mantine/modals";
import { useFieldArray, useForm } from "react-hook-form";

function CustomColumns({
  currentEventId,
  columns,
  defaultTicketColumns,
  customColumns,
  setCustomColumns,
  updateColumnsVisibility,
  makeCustomColumns,
  makeCustomColumnsSuccess,
}) {
  const [isCustomColumnsEnabled, setIsCustomColumnsEnabled] = useState(false); // Custom columns switch

  // Form for adding, editing and removing custom columns
  const {
    control,
    register,
    getValues,
    setValue,
    setError,
    formState: { isDirty, dirtyFields, errors },
  } = useForm();

  useEffect(() => {
    setValue(
      "customColumns",
      customColumns.map((col) => ({ name: col.name, id: col.id }))
    );
  }, [customColumns, setValue]);

  const { fields, append, remove } = useFieldArray({
    name: "customColumns",
    control,
  });

  useEffect(() => {
    if (columns) {
      // Set the value of custom columns switch accordingly
      const customColumns = columns.filter(
        (column) => !defaultTicketColumns.includes(column.name)
      );
      setCustomColumns(customColumns);

      setIsCustomColumnsEnabled(
        customColumns.some((column) => column.isVisible)
      );
    }
  }, [
    columns,
    setCustomColumns,
    defaultTicketColumns,
    setIsCustomColumnsEnabled,
  ]);

  const handleUpdateCustomColumns = ({ data }) => {
    // Validate before submitting to catch cases when there were no changes on name for new column
    if (data.some((col) => !col.id && col.name.trim() === "")) {
      validateColumnNames(
        data.findIndex((col) => !col.id),
        data.find((col) => !col.id).name
      );
    }

    const hasErrors = Object.values(errors.customColumns || {}).some(
      (col) => col.name?.message
    );

    if (hasErrors) {
      return;
    }

    makeCustomColumns({ columns: data });
  };

  const validateColumnNames = (index, name) => {
    const existingNames = customColumns.map((col) => col.name);

    // Clear previous errors for this index
    setError(`customColumns.${index}.name`, { type: "manual", message: "" });

    // Validation for empty names or duplicates
    if (!name || name.trim() === "") {
      setError(`customColumns.${index}.name`, {
        type: "manual",
        message: "Column name cannot be empty.",
      });
    } else if (existingNames.includes(name)) {
      setError(`customColumns.${index}.name`, {
        type: "manual",
        message: "Column name must be unique.",
      });
    }
  };

  return (
    <Stack>
      <Group
        style={{
          flexDirection: "row",
        }}
      >
        <Title order={4}>Enable custom columns</Title>
        <Switch
          name="customColumns"
          checked={isCustomColumnsEnabled ? true : false}
          onChange={() => {
            openConfirmModal({
              title: `Please confirm your choice`,
              children: (
                <Text size="sm">
                  Are you sure you want to{" "}
                  {isCustomColumnsEnabled ? "disable" : "enable"} custom
                  columns?
                </Text>
              ),
              labels: { confirm: "Confirm", cancel: "Cancel" },
              centered: true,
              confirmProps: { color: "red" },
              onCancel: () => {
                closeModal();
              },
              onConfirm: async () => {
                // Handle custom columns visibility
                updateColumnsVisibility({
                  eventId: currentEventId,
                  columns: customColumns.map((column) => ({
                    id: column.id,
                    name: column.name,
                    isVisible: !isCustomColumnsEnabled,
                  })),
                });

                if (isCustomColumnsEnabled) {
                  setCustomColumns([]); // Disable custom columns
                } else {
                  setCustomColumns(customColumns); // Enable custom columns
                }

                setIsCustomColumnsEnabled((prev) => !prev);
                closeModal();
              },
            });
          }}
        />
      </Group>

      <Paper shadow="md" p="sm" withBorder mb="xs" w={"70%"}>
        <Container
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconInfoCircle />
          <Container style={{ marginLeft: 10 }}>
            <Text style={{ fontSize: 13 }}>
              Enable custom columns to add columns to the tickets table.
            </Text>
            <Text style={{ fontSize: 13 }}>
              Custom columns are visible by default, but you can change
              visibility in the column visibility section.
            </Text>
            <Text style={{ fontSize: 13 }}>
              If you hide all custom columns, the custom columns feature will be
              disabled.
            </Text>
            <Text style={{ fontSize: 13 }}>
              You can add column values for ticket on EDIT/ CREATE TICKET page.
            </Text>
          </Container>
        </Container>
      </Paper>

      {isCustomColumnsEnabled && (
        <Group>
          <Fieldset legend="Custom columns" w="50%">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id || index}>
                <TextInput
                  label="Column name"
                  {...register(`customColumns.${index}.name`)}
                  value={field.name}
                  onChange={(e) => {
                    setValue(`customColumns.${index}.name`, e.target.value);
                    validateColumnNames(index, e.target.value);
                  }}
                  error={errors.customColumns?.[index]?.name.message}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove column
                </Button>
              </Fieldset>
            ))}

            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add column
            </Button>
          </Fieldset>

          <Button
            onClick={() =>
              handleUpdateCustomColumns({ data: getValues("customColumns") })
            }
            style={{ alignSelf: "flex-end" }}
            disabled={
              !isDirty || !dirtyFields.customColumns || makeCustomColumnsSuccess
            }
          >
            Save custom columns
          </Button>
        </Group>
      )}
    </Stack>
  );
}

export default CustomColumns;
