import { Button, Flex, Group, Text } from "@mantine/core";

import { modals } from "@mantine/modals";
import { useExportTickets } from "../../api/tickets";

const ExportTicketsModal = ({ eventId }) => {
  const exportTickets = useExportTickets();

  const handleExport = () => {
    exportTickets.mutate({ eventId }, { onSuccess: () => modals.closeAll() });
  };

  return (
    <Flex direction="column" gap="md">
      <Text size="sm">
        This will export all tickets to a CSV file. It will export with visible
        columns that are currently set in the table settings.
      </Text>

      <Group gap="xs">
        <Button onClick={handleExport} loading={exportTickets.isLoading}>
          Export
        </Button>
        <Button onClick={() => modals.closeAll()} color="red">
          Cancel
        </Button>
      </Group>
    </Flex>
  );
};

export const openExportTicketsModal = ({ eventId }) => {
  modals.open({
    title: "Export Tickets",
    children: <ExportTicketsModal eventId={eventId} />,
  });
};
