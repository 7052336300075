class UploadAdapter {
  // eslint-disable-next-line no-unused-vars
  async upload(url, file) {
    throw new Error("Upload adapter not implemented");
  }
}

export class FetchUploadAdapter extends UploadAdapter {
  async upload(url, file) {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type || "application/octet-stream",
      },
    });

    if (!response.ok) {
      throw new Error(`Upload failed: ${response.status}`);
    }
  }
}

// React Native adapter
export class RNUploadAdapter extends UploadAdapter {
  async upload(url, file) {
    const response = await fetch(url, {
      method: "PUT",
      body: file.uri ? await fetch(file.uri).then((r) => r.blob()) : file,
      headers: {
        "Content-Type": file.mimeType || "application/octet-stream",
      },
    });

    if (!response.ok) {
      throw new Error(`Upload failed: ${response.status}`);
    }

    return response;
  }
}

export class StorageError extends Error {
  constructor(message, code, details) {
    super(message);
    this.name = "StorageError";
    this.code = code;
    this.details = details;
  }
}

export class StorageClient {
  constructor(config) {
    this.apiBaseUrl = config.apiBaseUrl;
    this.adapter = config.adapter;
    this.authToken = config.authToken;
  }

  async uploadFile(file, authToken = this.authToken) {
    try {
      // Get presigned URL
      const presignResponse = await fetch(
        `${this.apiBaseUrl}/storage/presign`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (!presignResponse.ok) {
        throw new StorageError(
          presignResponse.status === 503
            ? "Storage not configured"
            : "Failed to get upload URL",
          presignResponse.status === 503
            ? "CONFIGURATION_MISSING"
            : "NETWORK_ERROR"
        );
      }

      const { url, fileKey } = await presignResponse.json();

      await this.adapter.upload(url, file);

      return { url, fileKey };
    } catch (error) {
      if (error instanceof StorageError) throw error;
      throw new StorageError("Upload failed", "UPLOAD_FAILED", error);
    }
  }
}

export const createWebStorageClient = (apiBaseUrl, authToken) => {
  return new StorageClient({
    apiBaseUrl,
    adapter: new FetchUploadAdapter(),
    authToken,
  });
};

export const createRNStorageClient = (apiBaseUrl) => {
  return new StorageClient({
    apiBaseUrl,
    adapter: new RNUploadAdapter(),
  });
};
